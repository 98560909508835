import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["view"];
import { FREE_GROUP_KEYS } from 'getting-started-shared-tasks/gettingStartedGroupKeys';
import {
// Consolidated Onboarding Keys
ONBOARDING_MARKETING_FREE, ONBOARDING_SALES_FREE, ONBOARDING_CMS_FREE, ONBOARDING_FREE_SERVICE } from 'getting-started-shared-tasks/onboardingKeys';
import { ONBOARDING_VIEWS, setOnboardingSettings } from 'user-context/onboardingSettings';
import { setDefaultGroupKey } from 'user-context/onboarding';
import { HubletUrl } from '../utils/urls';
export const getDefaultOnboardingSettings = () => {
  const {
    SALES,
    MARKETING,
    SERVICE,
    CMS
  } = ONBOARDING_VIEWS;
  return {
    views: {
      [MARKETING]: {
        groupKey: FREE_GROUP_KEYS.freeMarketingView,
        onboardingKey: ONBOARDING_MARKETING_FREE
      },
      [SALES]: {
        groupKey: FREE_GROUP_KEYS.freeSalesView,
        onboardingKey: ONBOARDING_SALES_FREE
      },
      [SERVICE]: {
        groupKey: FREE_GROUP_KEYS.freeServiceView,
        onboardingKey: ONBOARDING_FREE_SERVICE
      },
      [CMS]: {
        groupKey: FREE_GROUP_KEYS.freeContentView,
        onboardingKey: ONBOARDING_CMS_FREE
      }
    }
  };
};
export const getFinalOnboardingSettings = redirectConfig => {
  const isPathConfig = redirectConfig && typeof redirectConfig === 'object';
  let hubOnboardingSettings = {};

  // Turn the specific usecase redirect view settings into an onboarding
  // settings for that view (e.g. Sales). These might be configurations related
  // to persona usecases or experiments.
  if (isPathConfig) {
    hubOnboardingSettings = Object.assign({}, redirectConfig.view && {
      view: redirectConfig.view
    }, redirectConfig.onboardingKey && {
      onboardingKey: redirectConfig.onboardingKey
    }, redirectConfig.groupKey && {
      groupKey: redirectConfig.groupKey
    });
  }
  const {
      view = ONBOARDING_VIEWS.SALES
    } = hubOnboardingSettings,
    overrideOnboardingSettings = _objectWithoutPropertiesLoose(hubOnboardingSettings, _excluded);
  const onboardingSettings = getDefaultOnboardingSettings();

  // Merge the specific usecase settings into the default settings
  if (onboardingSettings.views) {
    onboardingSettings.views[view] = Object.assign({}, onboardingSettings.views[view], overrideOnboardingSettings);
  } else {
    onboardingSettings.views = {
      [view]: overrideOnboardingSettings
    };
  }

  // Make the override view the selected view when the user first sees their tasks
  onboardingSettings.selectedView = view;
  return onboardingSettings;
};
export const setDefaultOnboardingSettings = (onboardingSettings, auth, portalId, logger, signupExperimentTreatments) => {
  var _onboardingSettings$v;
  if (!auth.userId) {
    if (logger) {
      logger.error('No user id provided, skipping setting onboarding key');
    }
    return Promise.resolve();
  }
  if (!portalId) {
    if (logger) {
      logger.error('No portal id provided, skipping setting onboarding key');
    }
    return Promise.resolve();
  }
  if (signupExperimentTreatments) {
    // leaving this here for future experiment treatment logic
    // TODO: implement logic needed for experiments
  }
  const apiUrl = HubletUrl.getApiUrl();
  const requestOptions = {
    hubletOriginOverride: apiUrl
  };
  const settingsRequests = [setOnboardingSettings(onboardingSettings, auth.userId, portalId, requestOptions)];
  const {
    selectedView = ONBOARDING_VIEWS.SALES
  } = onboardingSettings;
  // groupKey also needs to be set in usercontext-app for setting user's default task group
  if ((_onboardingSettings$v = onboardingSettings.views) !== null && _onboardingSettings$v !== void 0 && (_onboardingSettings$v = _onboardingSettings$v[selectedView]) !== null && _onboardingSettings$v !== void 0 && _onboardingSettings$v.groupKey) {
    settingsRequests.push(setDefaultGroupKey(onboardingSettings.views[selectedView].groupKey, requestOptions));
  }
  return Promise.all(settingsRequests);
};