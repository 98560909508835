import styled from 'styled-components';
import UIIllustration from 'UIComponents/image/UIIllustration';
import { devices } from 'signup-constants/styles/viewports.styles';
export const StyledMessagingIllustration = styled(UIIllustration).withConfig({
  displayName: "Messagingstyles__StyledMessagingIllustration",
  componentId: "sc-59g831-0"
})(["height:", ";@media ", "{height:", ";}"], ({
  height
}) => height || '12rem', devices.large, ({
  height
}) => height || '12.5rem');
export const StyledIllustration = styled.div.withConfig({
  displayName: "Messagingstyles__StyledIllustration",
  componentId: "sc-59g831-1"
})(["display:", ";@media ", "{display:block;}"], props => props.$isFramedCentredStep ? ` block` : `none`, devices.desktop);