import { getSentryExtra } from '../error/getSentryExtra';
import { ExperimentKeys } from '../experiments/ExperimentKeys';
import { ExperimentParameterGroup } from '../experiments/ExperimentParameterGroup';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { NextStepManager } from '../flow-management/NextStepManager';
import { setNextStep } from '../flow-management/setNextStep';
import { isPreVerification } from '../flow-settings/settingsSelectors';
import Logger from '../lib/Logger';
import { redirectExistingUserAction } from '../store/auth/action-creators';
import { AuthActionTypes } from '../store/auth/action-types';
import { fetchExistingAccounts } from '../store/existing-accounts/action-creators';
import { unenrollExperiment } from '../store/experiments/action-creators';
import { getIsActiveAndAssignedToVariant } from '../store/experiments/selectors';
import { getNextSteps, getPostExistingAccountSelectionSteps } from '../store/flow/selectors';
import { fetchPortalTierInfo } from '../store/portal-tier/action-creators';
const LOG = Logger.getLogger('ExistingUserMiddleware');
export const ExistingUserMiddleware = store => next => action => {
  if (action.type === AuthActionTypes.ClickCreateNewAccountSameUser) {
    const isAssignedToACQ151 = getIsActiveAndAssignedToVariant(store.getState(), ExperimentKeys.ACQ0151, [ExperimentParameterGroup.Variant_A]);
    if (isAssignedToACQ151) {
      store.dispatch(unenrollExperiment(ExperimentKeys.ACQ0151));
    }
  }
  if ((action.type === AuthActionTypes.InitializeAuth && action.isAuthenticated || action.type === AuthActionTypes.ExistingOAuthUser) && isPreVerification()) {
    store.dispatch(fetchExistingAccounts()).catch(error => {
      LOG.error('Error fetching existing accounts on load', {
        extra: getSentryExtra(error)
      });
    });
    store.dispatch(fetchPortalTierInfo());
  }
  if (action.type === AuthActionTypes.PortalSelected) {
    const state = store.getState();
    const postExistingAccountSelection = getPostExistingAccountSelectionSteps(state);
    const nextSteps = getNextSteps(state);
    const shouldRedirectExistingUserAutomatically = getFlowConfig().shouldRedirectExistingUserAutomatically;
    if (postExistingAccountSelection.length > 0) {
      store.dispatch(setNextStep({
        rebuilder: NextStepManager.pushNextAndRemove(postExistingAccountSelection, nextSteps.filter(data => !postExistingAccountSelection.includes(data)))
      }));
    } else if (shouldRedirectExistingUserAutomatically) {
      store.dispatch(redirectExistingUserAction(action.payload));
    }
  }
  return next(action);
};