export let ViaParam;
(function (ViaParam) {
  ViaParam["CrmSignup"] = "crm-signup";
  ViaParam["MarketingSignup"] = "marketing-signup";
  ViaParam["SalesSignup"] = "sales-signup";
  ViaParam["ServiceSignup"] = "service-signup";
  ViaParam["CmsFreeSignup"] = "cms-free-signup";
  ViaParam["ClassroomTrainingSignup"] = "classroom-training-signup";
  ViaParam["PurchaseSignup"] = "purchase-signup";
  ViaParam["AcademySignup"] = "academy-signup";
  ViaParam["IntegrationsSignup"] = "integrations-signup";
  ViaParam["ConnectSignup"] = "connect-signup";
  ViaParam["ChatSpotSignup"] = "chatspot-signup";
})(ViaParam || (ViaParam = {}));