import { hubSpotConnectRedirect } from './productLinks';
import { getHublet, getIsNewUser } from '../store/app/selectors';
import { getQueryParam } from '../routing/getQueryParam';
export const getConnectFlowRedirect = state => {
  const loginRedirectUrl = getQueryParam('loginRedirectUrl');
  if (loginRedirectUrl) {
    return loginRedirectUrl;
  }
  const isNewUser = getIsNewUser(state);
  const hublet = getHublet(state);
  return hubSpotConnectRedirect({
    hublet,
    isNewUser
  });
};