/* hs-eslint ignored failing-rules */
/* eslint-disable no-bitwise */

function _hash(input) {
  let hash = 0;
  if (input.length === 0) {
    return hash;
  }
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return (hash >>> 0).toString(36);
}

/**
 * Generate a dummy company domain from a users email.
 * @param {string} email - The user email e.g. 'testuser@huspot.com'.
 * @return {string} a generated prefixed hashed domain of hubspot-developers-HASH_OF_EMAIL.com e.g. 'hubspot-developers-vceja8.com' .
 */
export const generateDevelopersDomainFromEmail = email => {
  return `hubspot-developers-${_hash(email)}.com`;
};