import { decorateRedirectUrl } from 'growth-onboarding-signup-redirect/redirect';
import { Flow } from 'signup-constants/Flow';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { getRouteParams } from '../routing/getRouteParams';
import { isFlow } from '../utils/isFlow';
import { getHubletApiUrl, getHubletAppUrl } from '../utils/urls';
import { ExperimentKeys } from '../experiments/ExperimentKeys';
import { ExperimentParameterGroup } from '../experiments/ExperimentParameterGroup';
import { Intent } from 'signup-constants/Intent';
export const redirectToIntent = ({
  intentLink,
  survey,
  portalId,
  hublet,
  signupType,
  signupExperimentTreatments
}) => {
  const {
    intentKey,
    queryParams
  } = getRouteParams();
  const allowUrlReplacement = isFlow(Flow.Sales);
  const isACQ151 = signupExperimentTreatments && signupExperimentTreatments[ExperimentKeys.ACQ0151] === ExperimentParameterGroup.Variant_A && intentKey === Intent.purchase;
  const includeParams = Object.assign({
    via: getFlowConfig().viaParam
  }, survey && survey['crmExperience'] && survey['crmExperience'] !== '' ? {
    crmExperience: survey['crmExperience']
  } : null, isACQ151 ? {
    isACQ151: 'true'
  } : null);
  const appUrl = getHubletAppUrl(hublet);
  const apiUrl = getHubletApiUrl(hublet);
  return decorateRedirectUrl({
    url: intentLink,
    portalId,
    allowUrlReplacement,
    signupType,
    survey,
    includeParams,
    queryParams,
    signupIntent: intentKey,
    appUrl,
    apiUrl
  });
};