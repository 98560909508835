import { ViaParam } from 'growth-onboarding-signup-redirect/types';
import { LayoutType } from 'signup-components/types';
import { isGoogleSignupSupported } from '../google-authentication/utils/isGoogleSignupSupported';
import { SignupApiFlowPath } from '../http-clients/SignupApiFlowPath';
import { OAuthProviders } from '../oauth/OAuthProviders';
import { userOnlyFlowOverrideRedirectCheck } from '../signup-completed-redirection/overrideRedirectCheck';
import { getScoreSalesProducts } from '../utils/scorePortal';
export const DefaultFlowOptions = {
  showMobileAppPrompt: false,
  shouldAddHubInterestStep: () => false,
  shouldSkipExistingAccountStep: false,
  isOptionalDomainEnabled: false,
  shouldShowBoxAccountCard: false,
  shouldShowTitleOnExistingUserView: false,
  shouldShowSignInButton: true,
  shouldHalveProgressBar: false,
  shouldPreFillCompanyName: true,
  oAuthsEnabled: {
    [OAuthProviders.Google]: isGoogleSignupSupported(),
    [OAuthProviders.Microsoft]: true,
    [OAuthProviders.Apple]: true
  },
  signupApiFlowPath: SignupApiFlowPath.Crm,
  viaParam: ViaParam.CrmSignup,
  shouldRedirectExistingUserAutomatically: true,
  scoringFunction: getScoreSalesProducts,
  createAccountTextI18nLabel: 'legal.createAccountTermsOfService',
  shouldSkipExistingAccountStepIfOneAccount: false,
  shouldSubmitSignupInterest: true,
  shouldSubmitPostSignup: true,
  shouldShowTosOnAccountCreationStep: true,
  shouldShowTosOnLandingPage: false,
  isUserOnlyFlow: false,
  shouldShowSprocket: true,
  getLayoutType: () => LayoutType.CentredStep,
  getAppLogo: () => ({
    altText: 'Hubspot'
  }),
  shouldUseWaveOnPostAccountCreation: true,
  shouldSkipHubletSelectionNewUser: true,
  shouldUseIPForAuthedHubletSelection: false
};
export const HSFXFlowOptions = Object.assign({}, DefaultFlowOptions, {
  shouldHalveProgressBar: true
});
export const FlowOptionsWithMobileAppPrompt = Object.assign({}, DefaultFlowOptions, {
  showMobileAppPrompt: true
});
export const DevelopersFlowOptions = Object.assign({}, DefaultFlowOptions, {
  shouldPreFillCompanyName: false
});
export const UserOnlyFlowOptions = Object.assign({}, DefaultFlowOptions, {
  overrideRedirectCheck: userOnlyFlowOverrideRedirectCheck,
  shouldSkipExistingAccountStep: true,
  shouldSubmitSignupInterest: false,
  shouldSubmitPostSignup: false,
  shouldShowTosOnAccountCreationStep: false,
  isUserOnlyFlow: true,
  shouldShowTosOnLandingPage: true
});