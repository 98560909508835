import { Step } from 'signup-constants/Step';
import { FlowStateManager } from '../flow-management/FlowStateManager';
import { getIsActiveAndAssignedToVariant } from '../store/experiments/selectors';
import { ExperimentKeys } from '../experiments/ExperimentKeys';
import { ExperimentParameterGroup } from '../experiments/ExperimentParameterGroup';
import { setFlowForCOAC0002, trackCOAC0002ExposureForGooglePrompt } from '../experiments/coac0002/COAC0002Utils';
export const setFlowAfterGoogleCredentialAuthentication = response => (dispatch, getState) => {
  const state = getState();
  const isEnrolledCOAC0002Variant = getIsActiveAndAssignedToVariant(state, ExperimentKeys.COAC0002, [ExperimentParameterGroup.Variant_A]);
  const stepsToRemove = [...(response.email ? [Step.LandingPage] : []), Step.Verification, Step.CodeVerification, Step.Password, ...(response.firstName && response.lastName ? [Step.Name] : [])];
  if (isEnrolledCOAC0002Variant) {
    dispatch(setFlowForCOAC0002({
      stepsToRemove
    }));
  } else {
    dispatch(FlowStateManager.removeSteps(stepsToRemove));
  }
  trackCOAC0002ExposureForGooglePrompt(state);
};