import { useSelector } from 'react-redux';
import { useHoneypotContext } from './HoneypotContext';
import { getCurrentStep } from '../store/flow/selectors';
import { useTracker } from '../tracking/useTracker';
import { useState } from 'react';
import { getHoneypotConfig } from './config';
const honeypotsRecordToList = honeypots => {
  return Object.keys(honeypots).map(step => step);
};
export const useHoneypots = () => {
  var _honeypots$currentSte;
  const {
    DISABLE_HONEYPOTS
  } = getHoneypotConfig();
  const [hasTracked, setHasTracked] = useState({});
  const {
    honeypots
  } = useHoneypotContext();
  const Tracker = useTracker();
  const currentStep = useSelector(getCurrentStep);
  const hasTrackedForStep = step => {
    var _hasTracked$step;
    return (_hasTracked$step = hasTracked === null || hasTracked === void 0 ? void 0 : hasTracked[step]) !== null && _hasTracked$step !== void 0 ? _hasTracked$step : false;
  };
  const trackHoneypotInteraction = () => {
    if (!currentStep) {
      return;
    }
    if (!hasTrackedForStep(currentStep)) {
      Tracker.signupInteraction('honeypotInteraction', {
        fields: honeypotsRecordToList(honeypots),
        stepName: currentStep
      });
      setHasTracked(prev => Object.assign({
        [currentStep]: true
      }, prev));
    }
  };
  if (DISABLE_HONEYPOTS) {
    return {
      honeypots: [],
      trackHoneypotInteraction: () => {}
    };
  }
  if (!currentStep) {
    return {
      honeypots: [],
      trackHoneypotInteraction: () => {}
    };
  }
  return {
    honeypots: (_honeypots$currentSte = honeypots[currentStep]) !== null && _honeypots$currentSte !== void 0 ? _honeypots$currentSte : [],
    trackHoneypotInteraction
  };
};