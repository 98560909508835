import { Industry } from 'signup-ui-lego-core/constants/Industry';
import { Data } from 'signup-constants/signupData/Data';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { Tracker } from '../store/tracking/action-creators';
import { getTypeaheadTrackedUserInput } from '../store/typeahead/selectors';
export const industryCollectionMethod = industryInput => {
  return industryInput ? 'input' : 'button';
};
export const trackIndustryCollected = (_action, state, dispatch) => {
  const industry = getSignupDataValue(state, Data.Industry);
  const industryUserInput = getTypeaheadTrackedUserInput(Data.Industry)(state);
  const selectedInputChoice = industryCollectionMethod(industryUserInput);
  if (!industry) {
    return;
  }
  dispatch(Tracker.industryCollected({
    industry,
    userInput: industryUserInput,
    selectedChoice: selectedInputChoice
  }));
  if (industry === Industry.RealEstate) {
    dispatch(Tracker.signupInteraction('real-estate'));
    return;
  }
  dispatch(Tracker.signupInteraction('not-real-estate'));
};