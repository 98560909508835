import { shuffleArray } from '../utils/data-structure-utils/shuffleArray';
import { getHoneypotConfig } from './config';
const DEFAULT_CONFIG = getHoneypotConfig();
const getFirstHalfOfArr = arr => {
  return arr.slice(0, arr.length / 2);
};
const getRandomFields = config => {
  const length = Math.floor(Math.random() * config.maxFieldsPerStep) + 1;

  // TODO: Field types and properties should be randomised.
  return Array.from({
    length
  }, (_, idx) => ({
    fieldType: 'text',
    key: `custom-input-${idx}`
  }));
};
export const getHoneypots = (steps, config = DEFAULT_CONFIG) => {
  // The honeypots are generated by shuffling the array of steps
  // and then grabbing the first half of the resulting array.
  // This is so we don't always generate honeypots on the same steps.
  const randomSteps = getFirstHalfOfArr(shuffleArray(steps));
  const honeypotRecord = randomSteps.reduce((honeypot, stepName) => {
    return Object.assign({}, honeypot, {
      [stepName]: getRandomFields(config)
    });
  }, {});
  return honeypotRecord;
};