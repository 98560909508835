/* hs-eslint ignored failing-rules */
/* eslint-disable prefer-promise-reject-errors */

import enviro from 'enviro';
import apiClient from 'hub-http/clients/apiClient';
import { getFullUrl } from 'hubspot-url-utils';
import Raven from 'raven-js';
import { APP_SCOPES as scopes, CONTENT_EMBED_SCOPES } from 'wordpress-scopes';
import { Metrics } from '../metrics/Metrics';
import { Data } from 'signup-constants/signupData/Data';
import { getHublet } from '../store/app/selectors';
import { getPortalId } from '../store/auth/selectors';
import { updateIntegratedAppAuthResponse } from '../store/integrations/action-creators';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { MetricsCounter } from '../metrics/MetricsCounter';
import { getQueryParam } from '../routing/getQueryParam';
import { Integration } from 'signup-constants/Integration';
const USER_UNAUTHORIZED = 'USER_UNAUTHORIZED';
const INTEGRATED_APP_CONFIG = {
  [Integration.Wordpress]: {
    clientIds: {
      qa: '5441b9e5-b561-451b-b3bc-5340bd8bf41e',
      prod: '6fde4e11-cbcf-4f7c-b1ba-8f57e9b02ae8'
    },
    redirectUrl: '/wordpress/v1/oauth/callback'
  },
  [Integration.ShopifyEmbedded]: {
    clientIds: {
      qa: '6017bf91-7afc-4e70-94a1-e953ddec76eb',
      prod: 'b6a21a00-f029-4f69-9348-82ed2f464713'
    },
    redirectUrl: '/shopify-integration/v2/oauth/callback'
  }
};
export const authorizeOAuthIntegration = integration => ({
  recaptchaToken,
  hubId,
  hublet,
  dispatch
}) => {
  const baseApi = getFullUrl('app-api', {
    hubletOverride: hublet
  });
  const config = INTEGRATED_APP_CONFIG[integration];
  const requiresContentEmbedScope = getQueryParam('requiresContentEmbedScope') === 'true';
  const clientId = enviro.isQa() ? config.clientIds.qa : config.clientIds.prod;
  const data = {
    clientId,
    recaptchaToken,
    hubId,
    redirectUri: `${baseApi}${config.redirectUrl}`,
    responseType: 'code',
    scopes,
    optionalScopes: requiresContentEmbedScope ? CONTENT_EMBED_SCOPES : []
  };
  const oauthApiPath = `/oauth/v1/auth?portalId=${hubId}`;
  return apiClient.post(`${baseApi}${oauthApiPath}`, {
    data
  }).then(response => apiClient.post(`${response.redirectUri}&portalId=${hubId}`)).then(response => {
    dispatch(updateIntegratedAppAuthResponse(response));
  });
};
export const makeOAuthAuthorizationRequest = integration => (state, dispatch) => {
  const hubId = getPortalId(state);
  if (!hubId) {
    Raven.captureMessage('No portalId found in state.');
    return Promise.reject();
  }
  const recaptchaToken = getSignupDataValue(state, Data.IntegratedAppRecaptchaToken);
  const hublet = getHublet(state);
  return authorizeOAuthIntegration(integration)({
    recaptchaToken,
    hubId,
    hublet,
    dispatch
  });
};
export const connectShopifyStore = async (state, dispatch) => {
  const connectState = getQueryParam('connectState');
  if (!connectState) {
    Raven.captureMessage('No connectState found in query params.');
    return Promise.reject();
  } else {
    const hubletOverride = getHublet(state);
    const hubId = getPortalId(state);
    const baseApi = getFullUrl('app-api', {
      hubletOverride
    });
    const completeInstallApiPath = `/shopify-integration/v1/install/complete?connectState=${connectState}&portalId=${hubId}`;
    await apiClient.post(`${baseApi}${completeInstallApiPath}`).catch(err => {
      Raven.captureException(err, {
        fingerprint: ['connectShopifyStore']
      });
    });
  }
  return makeOAuthAuthorizationRequest(Integration.ShopifyEmbedded)(state, dispatch);
};
export const validateOAuthPermissions = integration => state => {
  const config = INTEGRATED_APP_CONFIG[integration];
  const hubId = getPortalId(state);
  const hublet = getHublet(state);
  const clientId = enviro.isQa() ? config.clientIds.qa : config.clientIds.prod;
  const baseApi = `${getFullUrl('app-api', {
    hubletOverride: hublet
  })}`;
  return apiClient.post(`${baseApi}/oauth/v1/application-authorizations?portalId=${hubId}`, {
    data: {
      clientId,
      hubId,
      redirectUri: `${baseApi}${config.redirectUrl}`,
      responseType: 'code',
      scopes
    }
  }).catch(err => {
    if (err && err.data) {
      const parsedError = JSON.parse(err.data);
      if (parsedError.status === USER_UNAUTHORIZED) {
        Metrics.counter(MetricsCounter.UserUnauthorized, {
          integration
        }).increment();
      }
    }
    throw err;
  });
};