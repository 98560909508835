export let AppName;
(function (AppName) {
  AppName["Crm"] = "CrmSignup";
  AppName["FbCrm"] = "FbCrmSignup";
  AppName["Marketing"] = "MarketingSignup";
  AppName["Integrations"] = "IntegrationsSignup";
  AppName["Sales"] = "SalesSignup";
  AppName["Service"] = "ServiceSignup";
  AppName["ClassroomTraining"] = "ClassroomTrainingSignup";
  AppName["AssetProvider"] = "AssetProvider";
  AppName["Purchase"] = "PurchaseSignup";
  AppName["Academy"] = "AcademySignup";
  AppName["Developers"] = "DevelopersSignup";
  AppName["CmsDevelopers"] = "DeveloperContentHubSignup";
  AppName["HubspotForStartups"] = "HubSpotForStartupsSignup";
  AppName["SolutionsProvider"] = "SalesPartnerSignup";
  AppName["DirectoryListing"] = "DirectoryUserSignup";
  AppName["HubSpotPartners"] = "HubSpotPartnersSignup";
  AppName["Wordpress"] = "WordpressSignup";
  AppName["Trial"] = "TrialSignup";
  AppName["StandaloneCmsPartner"] = "PartnerContentHubSignup";
  AppName["CmsFree"] = "CmsFreeSignup";
  AppName["Connect"] = "ConnectSignup";
  AppName["Microapp"] = "MicroappSignup";
  AppName["Commerce"] = "CommerceSignup";
})(AppName || (AppName = {}));