export let LoadingKey;
(function (LoadingKey) {
  LoadingKey["InitiateVerificationRequest"] = "InitiateVerificationRequest";
  LoadingKey["CompleteVerificationRequest"] = "CompleteVerificationRequest";
  LoadingKey["InitiateVerificationRequestExpiredRefresh"] = "InitiateVerificationRequestExpiredRefresh";
  LoadingKey["SignupRequest"] = "SignupRequest";
  LoadingKey["StandardLoading"] = "StandardLoading";
  LoadingKey["BlankScreenLoading"] = "BlankScreenLoading";
  LoadingKey["LoginAfterSignup"] = "LoginAfterSignup";
  LoadingKey["PartnerExistingAccount"] = "PartnerExistingAccount";
  LoadingKey["PostSignupLoading"] = "PostSignupLoading";
  LoadingKey["ACQ151SignupRequest"] = "ACQ151SignupRequest";
  LoadingKey["ACQ151PortalCreated"] = "ACQ151PortalCreated";
  LoadingKey["COAC0002PortalCreated"] = "COAC0002PortalCreated";
})(LoadingKey || (LoadingKey = {}));