import { getStepConfig } from '../views/getStepConfig';
export const getStepData = stepKey => {
  const stepConfig = getStepConfig(stepKey);
  if (!stepConfig || !stepConfig.data) {
    return [];
  }
  return stepConfig.data;
};
export const getDataKeysByStep = (stepArray = []) => {
  return stepArray.reduce((acc, step) => [...acc, ...getStepData(step)], []);
};