import { ViaParam } from 'growth-onboarding-signup-redirect/types';
import { Flow } from 'signup-constants/Flow';
import { HubInterestOption } from 'signup-constants/signupData/HubInterestOption';
import { SignupEmbedderOptions } from 'signup-embedder';
import { AppName } from 'signup-ui-lego-core/constants/AppName';
import { Product } from 'signup-ui-lego-core/constants/Product';
import { ConvertGoalType } from '../convert/ConvertGoalType';
import { SignupApiFlowPath } from '../http-clients/SignupApiFlowPath';
import { getIntegrationFlowOptions } from '../integrations-flow/getIntegrationFlowOptions';
import { getMicroappFlowOptions } from '../microapp-flow/getMicroappFlowOptions';
import { OAuthProviders } from '../oauth/OAuthProviders';
import { getScoreAcademy, getScoreCmsDevelopers, getScoreDevelopers, getScoreLeadin, getScoreService } from '../utils/scorePortal';
import { DefaultFlowOptions, DevelopersFlowOptions, FlowOptionsWithMobileAppPrompt, HSFXFlowOptions, UserOnlyFlowOptions } from './FlowOptions';
import { FlowStepsMapping, GenericFlowSteps } from './FlowSteps';
import { getFlowStepsWithEmailVerification } from './getFlowStepsWithEmailVerification';
import { getConnectLogoConfig } from '../views-components/ConnectLogo';
import { getAcademyLogoConfig } from '../views-components/AcademyLogo';
const getFlowSteps = flow => {
  const flowSteps = FlowStepsMapping[flow];
  const shouldEnforceEmailVerification = SignupEmbedderOptions.getOptionValue(SignupEmbedderOptions.SIGNUP_ENFORCE_EMAIL_VERIFICATION);
  if (shouldEnforceEmailVerification) {
    return getFlowStepsWithEmailVerification(flowSteps);
  }
  return flowSteps;
};
const FlowConfigFactory = (steps = () => GenericFlowSteps, getFlowOptions = () => Object.assign({}, DefaultFlowOptions, {
  appName: AppName.Crm
})) => ({
  getFlowSteps: steps,
  getFlowOptions
});
export const FlowConfigRecord = {
  [Flow.Crm]: FlowConfigFactory(getFlowSteps(Flow.Crm), () => Object.assign({}, FlowOptionsWithMobileAppPrompt, {
    appName: AppName.Crm,
    convertGoal: {
      [ConvertGoalType.SignupInitiated]: '100314436',
      [ConvertGoalType.SignupCompleted]: '10038684',
      [ConvertGoalType.ExistingUser]: '100314437'
    }
  })),
  [Flow.Marketing]: FlowConfigFactory(getFlowSteps(Flow.Marketing), () => Object.assign({}, FlowOptionsWithMobileAppPrompt, {
    signupApiFlowPath: SignupApiFlowPath.Marketing,
    appName: AppName.Marketing,
    convertGoal: {
      [ConvertGoalType.SignupInitiated]: '100314438',
      [ConvertGoalType.SignupCompleted]: '10038687',
      [ConvertGoalType.ExistingUser]: '100314439'
    },
    viaParam: ViaParam.MarketingSignup,
    scoringFunction: getScoreLeadin,
    hubInterest: HubInterestOption.Marketing
  })),
  [Flow.Sales]: FlowConfigFactory(getFlowSteps(Flow.Sales), () => Object.assign({}, FlowOptionsWithMobileAppPrompt, {
    appName: AppName.Sales,
    convertGoal: {
      [ConvertGoalType.SignupInitiated]: '100314440',
      [ConvertGoalType.SignupCompleted]: '10038685',
      [ConvertGoalType.ExistingUser]: '100314441'
    },
    viaParam: ViaParam.SalesSignup,
    hubInterest: HubInterestOption.Sales
  })),
  [Flow.Service]: FlowConfigFactory(getFlowSteps(Flow.Service), () => Object.assign({}, FlowOptionsWithMobileAppPrompt, {
    signupApiFlowPath: SignupApiFlowPath.Service,
    appName: AppName.Service,
    convertGoal: {
      [ConvertGoalType.SignupInitiated]: '100314442',
      [ConvertGoalType.SignupCompleted]: '10038686',
      [ConvertGoalType.ExistingUser]: '100314446'
    },
    viaParam: ViaParam.ServiceSignup,
    scoringFunction: getScoreService,
    hubInterest: HubInterestOption.CustomerService
  })),
  [Flow.Trial]: FlowConfigFactory(getFlowSteps(Flow.Trial), () => Object.assign({}, FlowOptionsWithMobileAppPrompt, {
    signupApiFlowPath: SignupApiFlowPath.Trial,
    appName: AppName.Trial,
    convertGoal: {
      [ConvertGoalType.SignupInitiated]: '100314452',
      [ConvertGoalType.SignupCompleted]: '10038688',
      [ConvertGoalType.ExistingUser]: '100314453'
    },
    shouldSkipHubletSelectionNewUser: false
  })),
  [Flow.CmsFree]: FlowConfigFactory(getFlowSteps(Flow.CmsFree), () => Object.assign({}, DefaultFlowOptions, {
    isOptionalDomainEnabled: true,
    appName: AppName.CmsFree,
    viaParam: ViaParam.CmsFreeSignup,
    hubInterest: HubInterestOption.CMS,
    signupApiFlowPath: SignupApiFlowPath.CMSFree,
    convertGoal: {
      [ConvertGoalType.SignupInitiated]: '100320381',
      [ConvertGoalType.SignupCompleted]: '100320382',
      [ConvertGoalType.ExistingUser]: '100320388'
    }
  })),
  [Flow.ClassroomTraining]: FlowConfigFactory(getFlowSteps(Flow.ClassroomTraining), () => Object.assign({}, FlowOptionsWithMobileAppPrompt, {
    appName: AppName.ClassroomTraining,
    viaParam: ViaParam.ClassroomTrainingSignup
  })),
  [Flow.AssetProvider]: FlowConfigFactory(getFlowSteps(Flow.AssetProvider), () => Object.assign({}, FlowOptionsWithMobileAppPrompt, {
    signupApiFlowPath: SignupApiFlowPath.AssetProvider,
    appName: AppName.AssetProvider,
    flowSpecificProduct: Product.MarketingMarketplaceProvider,
    createAccountTextI18nLabel: 'legal.developerCreateAccountTermsOfService'
  })),
  [Flow.Purchase]: FlowConfigFactory(getFlowSteps(Flow.Purchase), () => Object.assign({}, DefaultFlowOptions, {
    appName: AppName.Purchase,
    convertGoal: {
      [ConvertGoalType.SignupInitiated]: '100314455',
      [ConvertGoalType.SignupCompleted]: '100314454',
      [ConvertGoalType.ExistingUser]: '100314456'
    },
    viaParam: ViaParam.PurchaseSignup,
    shouldSkipHubletSelectionNewUser: false
  })),
  [Flow.Academy]: FlowConfigFactory(getFlowSteps(Flow.Academy), () => Object.assign({}, DefaultFlowOptions, {
    signupApiFlowPath: SignupApiFlowPath.Academy,
    appName: AppName.Academy,
    getAppLogo: getAcademyLogoConfig,
    convertGoal: {
      [ConvertGoalType.SignupInitiated]: '100314450',
      [ConvertGoalType.SignupCompleted]: '10038689',
      [ConvertGoalType.ExistingUser]: '100314451'
    },
    shouldUseIPForAuthedHubletSelection: true,
    viaParam: ViaParam.AcademySignup,
    scoringFunction: getScoreAcademy
  })),
  [Flow.AcademyEmbedded]: FlowConfigFactory(getFlowSteps(Flow.Academy), () => Object.assign({}, DefaultFlowOptions, {
    signupApiFlowPath: SignupApiFlowPath.Academy,
    appName: AppName.Academy,
    convertGoal: {
      [ConvertGoalType.SignupInitiated]: '100314450',
      [ConvertGoalType.SignupCompleted]: '10038689',
      [ConvertGoalType.ExistingUser]: '100314451'
    },
    viaParam: ViaParam.AcademySignup,
    scoringFunction: getScoreAcademy
  })),
  [Flow.Developers]: FlowConfigFactory(getFlowSteps(Flow.Developers), () => Object.assign({}, DevelopersFlowOptions, {
    signupApiFlowPath: SignupApiFlowPath.Developers,
    appName: AppName.Developers,
    flowSpecificProduct: Product.Developers,
    scoringFunction: getScoreDevelopers,
    createAccountTextI18nLabel: 'legal.developerCreateAccountTermsOfService',
    shouldUseIPForAuthedHubletSelection: true
  })),
  [Flow.CmsDevelopers]: FlowConfigFactory(getFlowSteps(Flow.CmsDevelopers), () => Object.assign({}, DevelopersFlowOptions, {
    signupApiFlowPath: SignupApiFlowPath.CmsDevelopers,
    appName: AppName.CmsDevelopers,
    flowSpecificProduct: Product.StandaloneCmsDeveloper,
    scoringFunction: getScoreCmsDevelopers,
    createAccountTextI18nLabel: 'legal.developerCreateAccountTermsOfService',
    shouldUseIPForAuthedHubletSelection: true
  })),
  [Flow.SolutionsProvider]: FlowConfigFactory(getFlowSteps(Flow.SolutionsProvider), () => Object.assign({}, DefaultFlowOptions, {
    signupApiFlowPath: SignupApiFlowPath.SolutionsProvider,
    appName: AppName.SolutionsProvider,
    convertGoal: {
      [ConvertGoalType.SignupInitiated]: '100314460',
      [ConvertGoalType.SignupCompleted]: '10038691',
      [ConvertGoalType.ExistingUser]: '100314461'
    },
    shouldRedirectExistingUserAutomatically: false
  })),
  [Flow.FbCrm]: FlowConfigFactory(getFlowSteps(Flow.FbCrm), () => Object.assign({}, DefaultFlowOptions, {
    oAuthsEnabled: {
      [OAuthProviders.Google]: false,
      [OAuthProviders.Microsoft]: false,
      [OAuthProviders.Apple]: false
    },
    appName: AppName.FbCrm
  })),
  [Flow.DirectoryListing]: FlowConfigFactory(getFlowSteps(Flow.DirectoryListing), () => Object.assign({}, DefaultFlowOptions, {
    appName: AppName.DirectoryListing,
    shouldRedirectExistingUserAutomatically: false,
    signupApiFlowPath: SignupApiFlowPath.DirectoryListing,
    shouldUseWaveOnPostAccountCreation: false,
    shouldUseIPForAuthedHubletSelection: true
  })),
  [Flow.HubspotForStartups]: FlowConfigFactory(getFlowSteps(Flow.HubspotForStartups), () => Object.assign({}, HSFXFlowOptions, {
    signupApiFlowPath: SignupApiFlowPath.HubspotForStartups,
    appName: AppName.HubspotForStartups
  })),
  [Flow.HubSpotPartners]: FlowConfigFactory(getFlowSteps(Flow.HubSpotPartners), () => Object.assign({}, HSFXFlowOptions, {
    appName: AppName.HubSpotPartners
  })),
  [Flow.StandaloneCmsPartner]: FlowConfigFactory(getFlowSteps(Flow.StandaloneCmsPartner), () => Object.assign({}, DefaultFlowOptions, {
    shouldSkipExistingAccountStepIfOneAccount: true,
    signupApiFlowPath: SignupApiFlowPath.StandaloneCmsPartner,
    appName: AppName.StandaloneCmsPartner,
    shouldRedirectExistingUserAutomatically: false,
    flowSpecificProduct: Product.PartnerBase,
    oAuthsEnabled: {
      [OAuthProviders.Google]: false,
      [OAuthProviders.Microsoft]: false,
      [OAuthProviders.Apple]: false
    }
  })),
  [Flow.Integrations]: FlowConfigFactory(getFlowSteps(Flow.Integrations), () => getIntegrationFlowOptions()),
  [Flow.Microapp]: FlowConfigFactory(getFlowSteps(Flow.Microapp), () => getMicroappFlowOptions()),
  [Flow.Connect]: FlowConfigFactory(getFlowSteps(Flow.Connect), () => Object.assign({}, UserOnlyFlowOptions, {
    signupApiFlowPath: SignupApiFlowPath.Connect,
    appName: AppName.Connect,
    viaParam: ViaParam.ConnectSignup,
    shouldShowSprocket: false,
    getAppLogo: getConnectLogoConfig,
    createAccountTextI18nLabel: 'legal.connectCreateAccountTermsOfService',
    shouldShowTosOnAccountCreationStep: true
  })),
  [Flow.Commerce]: FlowConfigFactory(getFlowSteps(Flow.Commerce), () => Object.assign({}, FlowOptionsWithMobileAppPrompt, {
    appName: AppName.Commerce,
    signupApiFlowPath: SignupApiFlowPath.Commerce,
    convertGoal: {
      [ConvertGoalType.SignupInitiated]: '100327513',
      [ConvertGoalType.SignupCompleted]: '100327512',
      [ConvertGoalType.ExistingUser]: '100327514'
    }
  }))
};