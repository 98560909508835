import { usageTrackerMiddleware } from 'usage-tracker-redux';
import { getActiveExperimentsParameters } from '../experiments/ExperimentUtils';
import { stepsWithNoAlert } from '../routing/alertBeforeUnload';
import { getQueryParams } from '../routing/getQueryParams';
import { getResurrectionAttempt } from '../routing/getResurrectionAttempt';
import { debounce } from '../signup-data-validation/debounce';
import { Data } from 'signup-constants/signupData/Data';
import { hasPresetOptionalDomain } from '../step-company-domain/companyDomainUtils';
import { getDataKeysByStep } from '../step/getStepData';
import { AppActionTypes } from '../store/app/action-types';
import { getIsNewPortalCreated, getIsNewUser } from '../store/app/selectors';
import { AuthActionTypes } from '../store/auth/action-types';
import { ErrorActionTypes } from '../store/error/action-types';
import { getActiveError } from '../store/error/selectors';
import { ExperimentActionTypes } from '../store/experiments/action-types';
import { FlowActionTypes } from '../store/flow/action-types';
import { getCurrentStep, getNextSteps } from '../store/flow/selectors';
import { getActiveLoading } from '../store/loading-status/selectors';
import { getOnboardingUseCases, getPreAccountCreationSurveyData, getSignupDataSkipped, getSignupDataValue, getSurveyData } from '../store/signup-data/selectors';
import { TrackingActionTypes } from '../store/tracking/action-types';
import { getStepConfig } from '../views/getStepConfig';
import { visitorContextActionHandlers } from '../visitor-context-fetch/visitorContextActionHandlers';
import TrackerContainer from './TrackerContainer';
import { errorsTrackingHandler } from './errorsTrackingHandler';
import { EventName } from './EventName';
import { stepCompletedTrackingHandler } from './stepCompletedTrackingHandler';
import { trackEvent, trackInteraction } from './trackEvent';
import { APPLE_POPUP_CLOSED, APPLE_POPUP_BLOCKED } from '../apple/appleUtils';
import { OAuthToIdentityVerificationMethod } from '../oauth/OAuthMapping';
import { OAuthExistingUserErrorMessage } from '../store/oauth/action-creators';
import { OAuthActionTypes } from '../store/oauth/action-types';
const oAuthActionHandlers = {
  [OAuthActionTypes.AuthStarted]: action => {
    trackEvent(EventName.VerifyIdentityStarted, {
      verificationMethod: OAuthToIdentityVerificationMethod[action.provider]
    });
  },
  [AuthActionTypes.ExistingOAuthUser]: action => {
    trackInteraction({
      action: `${action.provider}-oauth-signin`
    });
    trackEvent(EventName.VerifyIdentityCompleted, {
      verificationMethod: OAuthToIdentityVerificationMethod[action.provider]
    });
  },
  [OAuthActionTypes.VerificationSucceeded]: action => {
    trackInteraction({
      action: `${action.provider}-oauth-success`
    });
    trackEvent(EventName.VerifyIdentityCompleted, {
      verificationMethod: OAuthToIdentityVerificationMethod[action.provider]
    });
  },
  [OAuthActionTypes.VerificationFailed]: action => {
    const isExistingUser = action.payload.status === OAuthExistingUserErrorMessage;
    if (!isExistingUser) {
      trackInteraction({
        action: `${action.provider}-oauth-error`
      });
    }
  },
  [OAuthActionTypes.AuthFailed]: action => {
    const userCancelledMicrosoftAuth = action.payload && action.payload.errorMessage && action.payload.errorMessage === 'User cancelled the flow.';
    const userClosedApplePopup = action.payload === APPLE_POPUP_CLOSED;
    const applePopupBlocked = action.payload === APPLE_POPUP_BLOCKED;
    if (userCancelledMicrosoftAuth || userClosedApplePopup) {
      trackInteraction({
        action: `${action.provider}-oauth-user-cancel`
      });
    } else if (applePopupBlocked) {
      trackInteraction({
        action: `${action.provider}-oauth-popup-blocked`
      });
    } else {
      trackInteraction({
        action: `${action.provider}-oauth-auth-error`
      });
    }
  },
  [AuthActionTypes.OAuthAuthenticationFailed]: action => {
    trackInteraction({
      action: `${action.provider}-signin-error`
    });
  }
};
const actionHandlers = Object.assign({}, oAuthActionHandlers, visitorContextActionHandlers, {
  [FlowActionTypes.StepCompleted]: (action, state, dispatch) => {
    const currentStep = action.stepKey || getCurrentStep(state);
    const currentError = getActiveError(state);
    const currentLoading = getActiveLoading(state);
    const nextSteps = getNextSteps(state);
    const isNextStepsEmpty = nextSteps.length !== 0;
    const nextStepName = isNextStepsEmpty ? nextSteps[0] : null;
    let properties = {};
    const dataKeys = getDataKeysByStep([currentStep]);
    if (currentStep) {
      const stepConfig = getStepConfig(currentStep);
      const stepName = currentStep || currentError || currentLoading || 'step-unknown';
      properties = Object.assign({
        stepName
      }, nextStepName && {
        nextStepName
      });
      if (dataKeys.length === 1) {
        properties = Object.assign({}, properties, {
          isSkipped: getSignupDataSkipped(state, dataKeys[0])
        });
      }

      // Include any extra step properties that should be tracked
      if (stepConfig && stepConfig.getStepPropertiesToTrack) {
        properties = Object.assign({}, properties, stepConfig.getStepPropertiesToTrack(state));
      }

      // Include the selected option for this step.
      if (stepConfig && stepConfig.trackSelectedOption) {
        dataKeys.forEach(dataKey => {
          let value;
          if (dataKey === Data.UseCase) {
            value = getOnboardingUseCases(state) || [];
          } else {
            value = [getSignupDataValue(state, dataKey)];
          }
          properties = Object.assign({}, properties, {
            selectedOption: value,
            numberOfDisplayed: value.length
          });
        });
      }
      const trackingHandler = stepCompletedTrackingHandler(currentStep);
      if (trackingHandler) {
        trackingHandler(action, state, dispatch);
      }
    }
    trackEvent(EventName.StepCompleted, properties);
  },
  [ErrorActionTypes.SetError]: (action, state, dispatch) => {
    const activeError = getActiveError(state);
    if (activeError) {
      const trackingHandler = errorsTrackingHandler(activeError);
      if (trackingHandler) {
        trackingHandler(action, state, dispatch);
      }
    }
  },
  [AppActionTypes.SignupCompleted]: (action, state) => {
    const {
      theme
    } = getQueryParams();
    const wasOptionalPhoneNumberStepSkipped = getSignupDataSkipped(state, Data.OptionalPhoneNumber);
    const resurrectionAttempt = getResurrectionAttempt();
    const activeExperimentParameters = getActiveExperimentsParameters(state);
    const signupCompletedProperties = Object.assign({
      newUser: getIsNewUser(state),
      experimentParameter: activeExperimentParameters,
      doesNotHaveDomain: hasPresetOptionalDomain(state),
      resurrectionAttempt,
      phoneNumberSubmitted: !wasOptionalPhoneNumberStepSkipped,
      hasBeenAnonymouslyTracked: TrackerContainer.wasAnonymouslyTracked,
      theme
    }, getPreAccountCreationSurveyData(state));
    trackEvent(EventName.SignupCompleted, signupCompletedProperties, true);
  },
  [TrackingActionTypes.SurveyInteraction]: (action, state) => {
    trackEvent(EventName.SurveyInteraction, Object.assign({
      action: action.message
    }, getSurveyData(state), {
      experimentParameter: getActiveExperimentsParameters(state)
    }), true);
  },
  [TrackingActionTypes.SignupInitiated]: () => {
    const {
      theme
    } = getQueryParams();
    trackEvent(EventName.SignupInitiated, {
      theme
    }, true);
  },
  [TrackingActionTypes.AuthState]: action => {
    trackEvent(EventName.AuthState, {
      action: 'auth state',
      eventSubtype: action.message
    });
  },
  [TrackingActionTypes.SignupInteraction]: action => {
    trackInteraction(action.payload);
  },
  [TrackingActionTypes.StepView]: action => {
    trackEvent(EventName.View, {
      screen: action.screen,
      subscreen: action.subscreen,
      stepName: action.screen
    });
  },
  [TrackingActionTypes.MessagingScrolling]: debounce(action => {
    trackEvent(EventName.MessagingScrolling, {
      action: 'messaging-scrolling',
      stepName: action.screen,
      percentage: action.percentage
    });
  }, 300),
  [TrackingActionTypes.Click]: action => {
    trackEvent(EventName.Click, {
      action: action.message,
      linkLocation: action.linkLocation
    });
  },
  [TrackingActionTypes.LeavePage]: (action, state) => {
    const currentStep = getCurrentStep(state);
    const shouldTrack = currentStep && !stepsWithNoAlert.includes(currentStep) && !getActiveError(state);
    if (shouldTrack) {
      trackInteraction({
        action: action.message,
        properties: {
          leavingStep: currentStep
        },
        sendAsBeacon: true
      });
    }
  },
  [TrackingActionTypes.FormError]: action => {
    trackEvent(EventName.FormError, {
      eventSubtype: action.eventSubtype,
      formError: action.formError
    });
  },
  [TrackingActionTypes.BackButton]: action => {
    trackEvent(EventName.BackButton, {
      action: 'back-button-clicked',
      fromStep: action.fromStep,
      toStep: action.toStep
    });
  },
  [TrackingActionTypes.CreationError]: action => {
    trackEvent(EventName.CreationError, {
      action: action.actionMessage,
      statusMessage: action.statusMessage
    });
  },
  [TrackingActionTypes.UtmVerification]: action => {
    trackEvent(EventName.UtmVerification, {
      action: 'utm-verification',
      utmMedium: action.utmMedium,
      utmSource: action.utmSource
    });
  },
  [AuthActionTypes.ExistingUser]: () => {
    trackInteraction({
      action: 'selected existing user'
    });
  },
  [AuthActionTypes.ClickCreateNewAccountSameUser]: action => {
    trackInteraction({
      action: action.hubless ? 'hubless-selected-create-new-account' : 'selected-create-new-portal'
    });
  },
  [TrackingActionTypes.VerifyIdentityCompleted]: action => {
    const resurrectionAttempt = getResurrectionAttempt();
    trackEvent(EventName.VerifyIdentityCompleted, {
      verificationMethod: action.verificationMethod,
      resurrectionAttempt
    });
  },
  [TrackingActionTypes.VerifyIdentityStarted]: action => {
    trackEvent(EventName.VerifyIdentityStarted, {
      verificationMethod: action.verificationMethod
    });
  },
  [TrackingActionTypes.SignupRedirect]: (action, state) => {
    const newPortal = getIsNewPortalCreated(state);
    const newUser = getIsNewUser(state);
    trackEvent(EventName.SignupRedirect, {
      url: action.url,
      newUser,
      newPortal
    });
  },
  [ExperimentActionTypes.TrackExposure]: action => {
    trackEvent(EventName.ExperimentExposure, {
      action: action.message
    }, true);
  },
  [TrackingActionTypes.InvalidDomain]: action => {
    trackEvent(EventName.InvalidDomainCollected, {
      action: 'invalid-domain',
      invalidDomain: action.invalidDomain
    });
  },
  [TrackingActionTypes.SurveyCompleted]: (action, state) => {
    trackEvent(EventName.SurveyCompleted, Object.assign({}, getSurveyData(state), {
      experimentParameter: getActiveExperimentsParameters(state)
    }), true);
  },
  [TrackingActionTypes.LoginSuccess]: action => {
    trackInteraction({
      action: 'login-success',
      properties: {
        totalNumberOfCalls: action.numOfRetries
      },
      sendAsBeacon: true
    });
  },
  [TrackingActionTypes.IndustryCollected]: action => {
    trackEvent(EventName.IndustryCollected, {
      industryName: action.industryName,
      industryId: action.industryId,
      userInput: action.userInput,
      selectedChoice: action.selectedChoice
    });
  },
  [ExperimentActionTypes.UnenrollExperiment]: action => {
    trackInteraction({
      action: `${action.experiment}-unenrolled`
    });
  },
  [ExperimentActionTypes.ReenrollExperiment]: action => {
    trackInteraction({
      action: `${action.experiment}-reenrolled`
    });
  },
  [TrackingActionTypes.SignupPageView]: action => {
    trackEvent(EventName.SignupPageView, {
      action: action.action
    });
  },
  [AppActionTypes.UpdateCookiePreferences]: action => {
    trackInteraction({
      action: 'update-cookie-preferences',
      properties: {
        currentValue: action.newValue
      }
    });
  }
});
export const UsageTrackingMiddleware = usageTrackerMiddleware(TrackerContainer.get(), actionHandlers);