export let EndpointKey;
(function (EndpointKey) {
  EndpointKey["InitiateVerification"] = "InitiateVerification";
  EndpointKey["InitiateVerificationExpiredRefresh"] = "InitiateVerificationExpiredRefresh";
  EndpointKey["CompleteVerificationByCodeExpiredRefresh"] = "CompleteVerificationByCodeExpiredRefresh";
  EndpointKey["CompleteVerification"] = "CompleteVerification";
  EndpointKey["CompleteVerificationByCode"] = "CompleteVerificationByCode";
  EndpointKey["Signup"] = "Signup";
  EndpointKey["CompanyInfo"] = "CompanyInfo";
  EndpointKey["VisitorContextFetch"] = "VisitorContextFetch";
  EndpointKey["SubmitSurveyAuthenticated"] = "SubmitSurveyAuthenticated";
  EndpointKey["BulkExperimentRequest"] = "BulkExperimentRequest";
  EndpointKey["TrackSignupCompleted"] = "TrackSignupCompleted";
  EndpointKey["StoreCOAC0002Treatment"] = "StoreCoac0002Treatment";
  EndpointKey["EndpointsGrowthSidePanel"] = "EndpointsGrowthSidePanel";
  EndpointKey["TrackSurveyCompleted"] = "TrackSurveyCompleted";
  EndpointKey["LoginNewPortal"] = "LoginNewPortal";
  EndpointKey["GetRecommendedHublet"] = "GetRecommendedHublet";
  EndpointKey["WordpressAuthorization"] = "WordpressAuthorization";
  EndpointKey["ShopifyEmbeddedAuthorization"] = "ShopifyEmbeddedAuthorization";
  EndpointKey["PartnerLookup"] = "PartnerLookup";
  EndpointKey["ValidateWordpressOAuthPermissions"] = "ValidateWordpressOAuthPermissions";
  EndpointKey["ValidateShopifyOAuthPermissions"] = "ValidateShopifyOAuthPermissions";
  EndpointKey["PartnerRegistrationLeadLookup"] = "PartnerRegistrationLeadLookup";
  EndpointKey["GateLookup"] = "GateLookup";
  EndpointKey["LoginWordpressPortal"] = "LoginWordpressPortal";
  EndpointKey["PostSignup"] = "PostSignup";
  EndpointKey["InitialInterest"] = "InitialInterest";
  EndpointKey["SetupAuth"] = "SetupAuth";
  EndpointKey["SetupAuthForNewPortal"] = "SetupAuthForNewPortal";
  EndpointKey["SetupAuthForOAuthExistingUser"] = "SetupAuthForOAuthExistingUser";
  EndpointKey["CheckScopesForBlogIdeasGeneratorNewUser"] = "CheckScopesForBlogIdeasGeneratorNewUser";
  EndpointKey["InvoiceSignupBridge"] = "InvoiceSignupBridge";
  EndpointKey["FetchEmailSignature"] = "FetchEmailSignature";
  EndpointKey["CreateContact"] = "CreateContact";
  EndpointKey["StoreEmailSignature"] = "StoreEmailSignature";
  EndpointKey["StoreOnboardingUrl"] = "StoreOnboardingUrl";
  EndpointKey["FetchOwners"] = "FetchOwners";
  EndpointKey["GetPortalTiers"] = "GetPortalTiers";
})(EndpointKey || (EndpointKey = {}));
export const isValidEndpointKey = endpointKey => {
  return Object.keys(EndpointKey).includes(endpointKey);
};