import { CRM_SIGNUP } from '../constants/signupConstants';
import { HubletUrl } from '../utils/urls';
const setupExperiences = [{
  redirectPath: portalId => {
    return {
      url: `${HubletUrl.getAppUrl()}/set-up-crm/${portalId}/use-cases?gosu0006Variant=variant_a`
    };
  },
  checks: [{
    signupExperimentTreatments: {
      'gosu-0006': 'variant_a'
    }
  }]
}, {
  redirectPath: portalId => {
    return {
      url: `${HubletUrl.getAppUrl()}/set-up-crm/${portalId}/use-cases?gosu0006Variant=variant_b`
    };
  },
  checks: [{
    signupExperimentTreatments: {
      'gosu-0006': 'variant_b'
    }
  }]
}, {
  redirectPath: portalId => {
    return {
      url: `${HubletUrl.getAppUrl()}/set-up-crm/${portalId}/templates`
    };
  },
  checks: [{
    signupType: CRM_SIGNUP
  }]
}];
export default setupExperiences;