import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { Data } from 'signup-constants/signupData/Data';
import { dataInvalid } from '../store/signup-data-validation/action-creators';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { codeVerificationFailed } from '../store/step-code-verification/action-creators';
import { ExperimentKeys } from '../experiments/ExperimentKeys';
import { getIsActiveAndAssignedToVariant } from '../store/experiments/selectors';
import { ExperimentParameterGroup } from '../experiments/ExperimentParameterGroup';
import { CodeVerificationErrorOption } from './types';
import { Tracker } from '../store/tracking/action-creators';
export const LOG = Logger.getLogger('handleCompleteVerificationError');
export const handleCodeVerificationError = (error, dispatch, getState) => {
  const state = getState();
  const code = getSignupDataValue(state, Data.CodeVerification);
  dispatch(codeVerificationFailed());
  dispatch(Tracker.signupInteraction('error-code-verification-failed'));
  LOG.error('/completeCodeVerification error', {
    extra: getSentryExtra(error)
  });
  if (getIsActiveAndAssignedToVariant(state, ExperimentKeys.ACQ0156, [ExperimentParameterGroup.Variant_A])) {
    dispatch(dataInvalid({
      dataKey: Data.CodeVerification,
      errorMessage: CodeVerificationErrorOption.ExpiredOrInvalidCode,
      validatedValue: code
    }));
  } else {
    dispatch(dataInvalid({
      dataKey: Data.CodeVerification,
      errorMessage: `data.${Data.CodeVerification}.error.invalidCode`,
      validatedValue: code
    }));
  }
};