import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { getIsNewPortalCreated } from '../store/app/selectors';
import { getIsAuthenticated } from '../store/auth/selectors';
import { isEndOfFlow } from '../store/flow/selectors';
import { hasPostSignupBeenSubmitted, isSignupCompletedFired } from '../store/http-responses/selectors';
export const canRedirectNewUser = state => {
  const overrideRedirectCheck = getFlowConfig().overrideRedirectCheck;
  if (overrideRedirectCheck) {
    return overrideRedirectCheck(state);
  }
  const flowRedirectFunc = getFlowConfig().canRedirect;
  const flowConfigRedirectCheck = flowRedirectFunc ? flowRedirectFunc(state) : true;
  return isEndOfFlow(state) && getIsNewPortalCreated(state) && getIsAuthenticated(state) && hasPostSignupBeenSubmitted(state) && isSignupCompletedFired(state) && flowConfigRedirectCheck;
};