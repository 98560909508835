import styled, { css, keyframes } from 'styled-components';
const config = {
  slidePx: 16,
  animationTime: 0.4,
  refreshTime: 0.2,
  animationDelay: 0.4,
  startOpacity: 0
};
const appear = keyframes(["0%{transform:translateX(", "px);opacity:0;}100%{transform:translateX(0);opacity:1;}"], config.slidePx);
const animationCss = css(["animation:", " ", "s cubic-bezier(0.42,0,0.58,1);animation-delay:", "s;animation-fill-mode:forwards;transition:all;opacity:0;"], appear, config.animationTime, config.animationDelay);
const refreshAnimationCss = css(["animation:", " ", "s cubic-bezier(0.42,0,0.58,1);animation-fill-mode:forwards;transition:all;opacity:0;"], appear, config.refreshTime);
export const AnimatedMessaging = styled.div.withConfig({
  displayName: "MessagingAnimationsstyles__AnimatedMessaging",
  componentId: "l35lns-0"
})(["", ""], props => {
  if (props.disable) return '';
  if (props.loaded) {
    if (!props.delay) {
      return refreshAnimationCss;
    }
    return animationCss;
  }
  return `
    transition: all;
    opacity: 0;
    `;
});