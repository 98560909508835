import { NextStepManager } from '../flow-management/NextStepManager';
import { setNextStep } from '../flow-management/setNextStep';
import { onOAuthExistingUser } from '../google-authentication/onOAuthExistingUser';
import Logger from '../lib/Logger';
import { Data } from 'signup-constants/signupData/Data';
import { setWhichOAuthLoading, setIsNewUser, setVerificationType } from '../store/app/action-creators';
import { newOAuthUser } from '../store/auth/action-creators';
import { completeStepAction } from '../store/flow/action-creators';
import { isStepInNextOrCurrentStep } from '../store/flow/selectors';
import { OAuthExistingUserErrorMessage } from '../store/oauth/action-creators';
import { OAuthActionTypes } from '../store/oauth/action-types';
import { bulkUpdate, setSignupDataValue } from '../store/signup-data/action-creators';
import { Tracker } from '../store/tracking/action-creators';
import { Step } from 'signup-constants/Step';
import { OAuthToDataToken } from './OAuthMapping';
import { onOAuthVerifyError } from './onOAuthVerifyError';
import { getOAuthVerificationType } from './utils/getOAuthVerificationType';
export const LOG = Logger.getLogger('OAuthUtilsActions');

/**
 * Triggering Actions:
 * - OAUTH_AUTH_SUCCEED
 * - VERIFICATION_FAILED
 * - VERIFICATION_SUCCEEDED
 * @description: handles the response from the OAuth Signup buttons.
 * If successful, there are 2 cases:
 * 1. 200 status with user already exists message - means the email is attached to an existing user. Adds the existing user step.
 * 2. updates data values in the store, adds and removes steps based on
 * what data values we received.
 * If unsuccessful:
 * 1. Failure - Logs and dispatches interactions. Doesn't change the step.
 */

export const OAuthMiddleware = store => next => action => {
  const state = store.getState();
  if (action.type === OAuthActionTypes.AuthSucceeded) {
    const {
      token
    } = action.payload;
    store.dispatch(setSignupDataValue(OAuthToDataToken[action.provider], token));
  }
  if (action.type === OAuthActionTypes.VerificationFailed) {
    const isExistingUser = action.payload.status === OAuthExistingUserErrorMessage;
    if (isExistingUser) {
      store.dispatch(onOAuthExistingUser(state, action.provider));
    } else {
      store.dispatch(onOAuthVerifyError(action.payload, action.provider));
      store.dispatch(setWhichOAuthLoading(null));
    }
  }
  if (action.type === OAuthActionTypes.VerificationSucceeded) {
    const {
      email,
      firstName,
      lastName,
      companyDomain,
      emailVerificationToken
    } = action.payload;
    store.dispatch(setVerificationType(getOAuthVerificationType(action.provider)));
    store.dispatch(newOAuthUser(action.provider));
    store.dispatch(setIsNewUser(true));
    const someUserInformationIsMissing = !email || !firstName || !lastName;
    if (someUserInformationIsMissing) {
      store.dispatch(Tracker.signupInteraction('oauth-success-empty', {
        provider: action.provider
      }));
    }
    const isCompanyDomainInNextSteps = isStepInNextOrCurrentStep(state, [Step.CompanyDomain]);
    const overrideHubSpotCompanyDomainInternal = companyDomain === 'hubspot.com' && isCompanyDomainInNextSteps && {
      [Data.CompanyDomain]: 'hubspot-test.com'
    };
    const dataToUpdate = Object.assign({
      [Data.Email]: email,
      [Data.FirstName]: firstName || '',
      [Data.LastName]: lastName || '',
      [Data.Token]: emailVerificationToken
    }, isCompanyDomainInNextSteps && {
      [Data.CompanyDomain]: companyDomain || ''
    }, overrideHubSpotCompanyDomainInternal);
    store.dispatch(bulkUpdate(dataToUpdate));
    store.dispatch({
      type: OAuthActionTypes.AuthDataStored,
      provider: action.provider
    });
    const stepsToRemove = [...(email ? [Step.Email] : []), ...(firstName && lastName ? [Step.Name] : []), Step.Verification, Step.CodeVerification, Step.Password, Step.LandingPage];
    const nextStepsToAdd = [...(!email ? [Step.Email] : []), ...(!firstName || !lastName ? [Step.Name] : [])];
    store.dispatch(setNextStep({
      rebuilder: NextStepManager.pushNextAndRemove(nextStepsToAdd, stepsToRemove)
    }));
    store.dispatch(completeStepAction({
      stepKey: Step.LandingPage
    }));
    store.dispatch(setWhichOAuthLoading(null));
  }
  return next(action);
};