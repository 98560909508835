import { createClient } from 'laboratory-lib';
import { getExperimentsConfig } from './getExperimentsConfig';
import { getPortalId } from '../store/auth/selectors';
export const getPortalIdTreatmentsClient = state => {
  return createClient({
    timeout: 3000,
    experiments: getExperimentsConfig(),
    identifiers: {
      portalId: getPortalId(state),
      utk: null
    }
  });
};