import { setError } from '../store/error/action-creators';
import { getActiveError } from '../store/error/selectors';
import { ErrorKey } from '../app-active-error/ErrorKey';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import HttpRequester from './HttpRequester';
import { runReducersFirst } from './runReducersFirst';
import { HttpResponseActionTypes } from '../store/http-responses/action-types';
const LOG = Logger.getLogger('HttpResponsesHandlerMiddleware');
const isLocalCrossOriginFrameError = error => error && error.message === 'Blocked a frame with origin "https://local.hubspotqa.com" from accessing a cross-origin frame.';
const promisifyEndpointMethodAndRethrow = (method, action, store) => {
  new Promise((resolve, reject) => {
    try {
      resolve(method());
    } catch (error) {
      if (isLocalCrossOriginFrameError(error)) {
        resolve(true);
      }
      reject(error);
    }
  }).catch(error => {
    LOG.error(`Endpoint [ ${action.endpointKey} ] Success Handler throwed an error.`, {
      extra: getSentryExtra(error)
    });
    const isAnErrorAlreadyRendered = getActiveError(store.getState());
    if (!isAnErrorAlreadyRendered) {
      store.dispatch(setError(ErrorKey.InternalError, error));
    }
  });
};

/**
 * Triggering Actions: HTTP Success, HTTP Error
 * @description: Triggers the success or error handler of the endpoint call.
 */

export const HttpResponsesHandlerMiddleware = store => next => action => {
  const dispatchResult = runReducersFirst(next, action);
  if (action.type === HttpResponseActionTypes.Success) {
    promisifyEndpointMethodAndRethrow(() => HttpRequester.handleSuccess(action, store.dispatch, store.getState), action, store);
  }
  if (action.type === HttpResponseActionTypes.Error) {
    promisifyEndpointMethodAndRethrow(() => HttpRequester.handleError(action, store.dispatch, store.getState), action, store);
  }
  return dispatchResult;
};