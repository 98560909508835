import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import { getFlowKey } from '../routing/getFlowKey';
import { Data } from 'signup-constants/signupData/Data';
import { getCurrentStep } from '../store/flow/selectors';
import { getSignupDataValue } from '../store/signup-data/selectors';
export const getStepTitle = state => {
  const currentStep = getCurrentStep(state);
  const industry = getSignupDataValue(state, Data.Industry);
  const titleWithData = `views.${currentStep}.title-${Data.Industry}.${industry}`;
  const flowKey = getFlowKey();
  const flowTitle = `views.${currentStep}.title.${flowKey}`;
  const defaultTitle = `views.${currentStep}.title.default`;
  const title = isValidI18nKey(flowTitle) ? flowTitle : defaultTitle;
  return isValidI18nKey(titleWithData) ? titleWithData : title;
};