export const initialPurchaseState = {
  productInfo: {
    productDisplayName: '',
    priceInUSD: 0,
    bundledProductsDisplayName: [],
    skuId: 0,
    quantity: 0
  },
  isMonthlySingleProduct: false,
  hasSeatsChanged: false,
  additionalSeats: 0
};
const purchaseReducer = (state = initialPurchaseState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
export default purchaseReducer;