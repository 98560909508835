import { useSelector } from 'react-redux';
import { getActiveError } from '../store/error/selectors';
import { getPostAccountCreationSteps, getPostVerificationSteps } from '../store/flow/selectors';
import { getActiveLoading } from '../store/loading-status/selectors';
import { getStepConfig } from '../views/getStepConfig';
import { Step } from 'signup-constants/Step';
import { useCurrentStep } from './useCurrentStep';
import { useIsFramedCentredModalStep } from './useIsFramedCerntredModalStep';
export const useIsFramedCentredStep = () => {
  const {
    currentStep
  } = useCurrentStep();
  const activeError = useSelector(getActiveError);
  const step = activeError || currentStep;
  const stepConfig = getStepConfig(step);
  const isFramedCentredStep = stepConfig && stepConfig.isFramedCentredStep || false;
  const activeLoading = useSelector(getActiveLoading);
  const postVerificationSteps = useSelector(getPostVerificationSteps);
  const postAccountCreation = useSelector(getPostAccountCreationSteps);
  const isPostAccountCreationStep = postAccountCreation.includes(currentStep);
  const {
    isFramedCentredModalStep
  } = useIsFramedCentredModalStep();

  // In the Wordpress flow, the CRM Experience step is before account creation
  if (step === Step.CrmExperienceLevel && postVerificationSteps.includes(Step.CrmExperienceLevel)) {
    return {
      isFramedCentredStep: true
    };
  }
  if (activeLoading || isPostAccountCreationStep || activeError || !isFramedCentredStep || isFramedCentredModalStep) {
    return {
      isFramedCentredStep: false
    };
  }
  return {
    isFramedCentredStep
  };
};