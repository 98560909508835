export let ExperimentKeys;
(function (ExperimentKeys) {
  ExperimentKeys["ACQ0151"] = "acq-0151";
  ExperimentKeys["ACQ0156"] = "acq-0156";
  ExperimentKeys["COAC0002"] = "coac-0002";
  ExperimentKeys["ACQ0154"] = "acq-0154";
  ExperimentKeys["ACQ0157"] = "acq-0157";
  ExperimentKeys["GOSU0006"] = "gosu-0006";
})(ExperimentKeys || (ExperimentKeys = {}));
export const getExperimentKeys = () => {
  return Object.values(ExperimentKeys);
};
export const TEST_KEY = 'TEST-002';