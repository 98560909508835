/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

/**
 * Creates a given middleware that has promise processing capabilities.
 *
 * The return value of the given middleware argument will be wrapped
 * into a Promise.resolve call. Subsequent calls to the middleware
 * will be ignored until the promise returned resolves.
 *
 * @param {Function<{ store: import('redux').Store }>} middleware The middleware to be wrapped.
 * @returns Function Wrapped version of the given middleware
 */

export const PromisableMiddleware = middleware => {
  let isProcessing = false;
  const setIsProcessing = value => {
    isProcessing = value;
  };
  return store => next => action => {
    const state = next(action);
    if (!isProcessing) {
      Promise.resolve(middleware({
        store,
        next,
        action,
        setIsProcessing
      })).finally(() => isProcessing = false);
    }
    return state;
  };
};