import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VerificationType } from '../app/VerificationType';
import { Data } from 'signup-constants/signupData/Data';
import { useSignupData } from '../signup-data/useSignupData';
import { setIsNewUser, setVerificationType } from '../store/app/action-creators';
import { useTrackSignupInitiated } from '../tracking/useTrackSignupInitiated';
import { useView } from '../views/useView';
import { getActiveExperimentsIds } from '../store/experiments/selectors';
import { ExperimentKeys } from '../experiments/ExperimentKeys';
import { TrackWhen, useExperiment } from '../experiments/useExperiment';
export const useLandingPage = stepKey => {
  const {
    submit
  } = useView({
    stepKey
  });
  const {
    setData: setToken
  } = useSignupData(Data.Token);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIsNewUser(true));
  }, [dispatch]);
  const {
    isAssignedToVariant: isACQ0151Variant
  } = useExperiment(ExperimentKeys.ACQ0151, TrackWhen.never);
  const activeExperimentIds = useSelector(getActiveExperimentsIds);
  const isACQ0151StillEnrolledInVariant = isACQ0151Variant() && activeExperimentIds.includes(ExperimentKeys.ACQ0151);
  useTrackSignupInitiated(!isACQ0151StillEnrolledInVariant);
  const chooseEmailSignUp = () => {
    setToken(null);
    dispatch(setVerificationType(VerificationType.EmailByCode));
    submit();
  };
  return {
    chooseEmailSignUp
  };
};