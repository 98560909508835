import { Flow } from 'signup-constants/Flow';
import { getFlowKey } from '../routing/getFlowKey';
import { getApplicationDomain } from '../constants/apiEnvironments';
import { getHubSpotForStartupsRedirect } from './getHSFXFallbackRedirectUrl';
import { hubSpotConnectRedirect } from './productLinks';
const getFlowToFallbackUrl = new Map([[Flow.Academy, (portalId, hublet) => `${getApplicationDomain(hublet)}/academy/${portalId}`], [Flow.HubspotForStartups, (portalId, hublet) => getHubSpotForStartupsRedirect({
  portalId,
  hublet
})], [Flow.Connect, (portalId, hublet, isNewUser) => hubSpotConnectRedirect({
  portalId,
  hublet,
  isNewUser
})]]);
export const getFallbackOnboardingUrl = (portalId, hublet, isNewUser) => {
  const flowKey = getFlowKey();
  const fallback = getFlowToFallbackUrl.get(flowKey);
  if (fallback) {
    return fallback(portalId, hublet, isNewUser);
  }
  return `${getApplicationDomain(hublet)}/getting-started/${portalId}`;
};