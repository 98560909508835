export let SignupApiFlowPath;
(function (SignupApiFlowPath) {
  SignupApiFlowPath["SolutionsProvider"] = "spp";
  SignupApiFlowPath["Crm"] = "crm";
  SignupApiFlowPath["Marketing"] = "marketing";
  SignupApiFlowPath["Service"] = "service";
  SignupApiFlowPath["AssetProvider"] = "asset-provider";
  SignupApiFlowPath["Academy"] = "academy";
  SignupApiFlowPath["Wordpress"] = "wordpress";
  SignupApiFlowPath["Developers"] = "developers";
  SignupApiFlowPath["CmsDevelopers"] = "cms-developer";
  SignupApiFlowPath["HubspotForStartups"] = "hubspot-for-startups";
  SignupApiFlowPath["Trial"] = "trial-signup";
  SignupApiFlowPath["StandaloneCmsPartner"] = "cms-partner";
  SignupApiFlowPath["CMSFree"] = "cms-free";
  SignupApiFlowPath["Connect"] = "connect-hubless";
  SignupApiFlowPath["DirectoryListing"] = "directory-listing";
  SignupApiFlowPath["Microapp"] = "microapp";
  SignupApiFlowPath["Commerce"] = "commerce";
})(SignupApiFlowPath || (SignupApiFlowPath = {}));