import { ONBOARDING_VIEWS } from 'user-context/onboardingSettings';
import { ONBOARDING_FREE_SERVICE } from 'getting-started-shared-tasks/onboardingKeys';
import { FREE_GROUP_KEYS } from 'getting-started-shared-tasks/gettingStartedGroupKeys';
import { HubletUrl } from '../utils/urls';
import { SERVICE_SIGNUP } from '../constants/signupConstants';
const servicesExperiencesChecks = [{
  redirectPath: portalId => {
    return {
      url: `${HubletUrl.getAppUrl()}/set-up-crm/${portalId}/templates`,
      view: ONBOARDING_VIEWS.SERVICE,
      onboardingKey: ONBOARDING_FREE_SERVICE,
      groupKey: FREE_GROUP_KEYS.freeServiceView
    };
  },
  checks: [{
    signupType: SERVICE_SIGNUP
  }]
}];
export default servicesExperiencesChecks;