import illustrationUrls from './illustrationUrls';

// @ts-expect-error bender-url not typed
import recaptchaVerification from 'bender-url!../../images/recaptcha-verification.svg';
// @ts-expect-error bender-url not typed
import connectLogo from 'bender-url!../../images/connect.com-full-logo-color.png';
// @ts-expect-error bender-url not typed
import connectMobileLogo from 'bender-url!../../images/connect.com-infinity-icon-color.png';
// @ts-expect-error bender-url not typed
import crmCentralPlace1x from 'bender-url!../../images/crm-central-place/crm-central-place@1x.svg';
// @ts-expect-error bender-url not typed
import crmCentralPlace2x from 'bender-url!../../images/crm-central-place/crm-central-place@2x.svg';
// @ts-expect-error bender-url not typed
import crmCentralPlace3x from 'bender-url!../../images/crm-central-place/crm-central-place@3x.svg';
// @ts-expect-error bender-url not typed
import blondeShelley1x from 'bender-url!../../images/blonde-shelley/blonde-shelley@1x.png';
// @ts-expect-error bender-url not typed
import blondeShelley2x from 'bender-url!../../images/blonde-shelley/blonde-shelley@2x.png';
// @ts-expect-error bender-url not typed
import blondeShelley3x from 'bender-url!../../images/blonde-shelley/blonde-shelley@3x.png';
// @ts-expect-error bender-url not typed
import wordpressAuthorizationConnect from 'bender-url!../../images/wordpress-authorization-connect.svg';
// @ts-expect-error bender-url not typed
import shopifyEmbeddedAuthorizationConnect from 'bender-url!../../images/shopify-authorization-connect.svg';
// @ts-expect-error bender-url not typed
import emailAnimation from 'bender-url!../../images/animated-images/email_open.webm';
// @ts-expect-error bender-url not typed
import academyLogo from 'bender-url!../../images/academy/hubspot-academy-logo.svg';
// @ts-expect-error bender-url not typed
import microsoftLogo from 'bender-url!../../images/logos/microsoft.png';
// @ts-expect-error bender-url not typed
import microsoftWhiteLogo from 'bender-url!../../images/logos/microsoft-white.png';
// @ts-expect-error bender-url not typed
import linkedinLogo from 'bender-url!../../images/logos/linkedin.png';
// @ts-expect-error bend-url not typed
import salesExtensionInstall from 'bender-url!../../images/email-tracking-flow/sales-extension-install.png';
// @ts-expect-error bend-url not typed
import checkSign from 'bender-url!../../images/email-tracking-flow/check-sign.png';
// @ts-expect-error bend-url not typed
import salesExtensionReview from 'bender-url!../../images/email-tracking-flow/sales-extension-review.png';
// @ts-expect-error bender-url not typed
import welcomeImage from 'bender-url!../../images/welcome.svg';
// @ts-expect-error bender-url not typed
import appleLogo from 'bender-url!../../images/logos/apple.png';
// @ts-expect-error bender-url not typed
import appleMailLogo from 'bender-url!../../images/logos/apple-mail.png';
// @ts-expect-error bender-url not typed
import appleWhiteLogo from 'bender-url!../../images/logos/apple-white.png';
// @ts-expect-error bender-url not typed
import yahooLogo from 'bender-url!../../images/logos/yahoo.png';
// @ts-expect-error bender-url not typed
import outlookLogo from 'bender-url!../../images/logos/outlook.png';
// @ts-expect-error bender-url not typed
import gmailLogo from 'bender-url!../../images/logos/gmail.png';
// @ts-expect-error bender-url not typed
import arrowIcon from 'bender-url!../../images/arrow-icon.png';
// @ts-expect-error bender-url not typed
import stars from 'bender-url!../../images/gswh-star.png';
// @ts-expect-error bender-url not typed
import generateBlogPost from 'bender-url!../../images/generate-blog-post.png';
// @ts-expect-error bender-url not typed
import landingPageCreator from 'bender-url!../../images/landing-page-creator.png';
// @ts-expect-error bender-url not typed
import template from 'bender-url!../../images/template.svg';
// @ts-expect-error bender-url not typed
import customers from 'bender-url!../../images/aha-moment/customers.svg';
// @ts-expect-error bender-url not typed
import integrations from 'bender-url!../../images/aha-moment/integrations.svg';
// @ts-expect-error bender-url not typed
import landingPage from 'bender-url!../../images/aha-moment/landing-page.svg';
// @ts-expect-error bender-url not typed
import productTour from 'bender-url!../../images/aha-moment/product-tour.svg';
// @ts-expect-error bender-url not typed
import academy from 'bender-url!../../images/aha-moment/academy.svg';
// @ts-expect-error bender-url not typed
import salesFree from 'bender-url!../../images/illustrations/sales-free.svg';
// @ts-expect-error bender-url not typed
import pencil from 'bender-url!../../images/illustrations/pencil.svg';
// @ts-expect-error bender-url not typed
import target from 'bender-url!../../images/illustrations/target.svg';
// @ts-expect-error bender-url not typed
import leads from 'bender-url!../../images/illustrations/leads.svg';
// @ts-expect-error bender-url not typed
import globe from 'bender-url!../../images/airplane-globe-around-animated.gif';
// @ts-expect-error bender-url not typed
import hubletEu from 'bender-url!../../images/hublet-eu.svg';
// @ts-expect-error bender-url not typed
import hubletUS from 'bender-url!../../images/hublet-us.svg';
// @ts-expect-error bender-url not typed
import hubletNA3 from 'bender-url!../../images/hublet-na3.svg';
// @ts-expect-error bender-url not typed
import hubletAP1 from 'bender-url!../../images/hublet-ap1.svg';
// @ts-expect-error bender-url not typed
import useCase from 'bender-url!../../images/use_case_content.svg';
// @ts-expect-error bender-url not typed
import backgroundStars from 'bender-url!../../images/background_stars.svg';
// @ts-expect-error bender-url not typed
import gauge from 'bender-url!../../images/gauge.svg';
export default Object.assign({
  crmCentralPlace1x,
  crmCentralPlace2x,
  crmCentralPlace3x,
  blondeShelley1x,
  blondeShelley2x,
  blondeShelley3x,
  recaptchaVerification,
  wordpressAuthorizationConnect,
  shopifyEmbeddedAuthorizationConnect,
  emailAnimation,
  connectLogo,
  connectMobileLogo,
  academyLogo,
  microsoftLogo,
  microsoftWhiteLogo
}, illustrationUrls, {
  salesExtensionInstall,
  salesExtensionReview,
  checkSign,
  welcomeImage,
  appleLogo,
  appleMailLogo,
  appleWhiteLogo,
  arrowIcon,
  stars,
  generateBlogPost,
  landingPageCreator,
  template,
  customers,
  integrations,
  landingPage,
  productTour,
  academy,
  salesFree,
  pencil,
  target,
  leads,
  globe,
  hubletEu,
  hubletUS,
  hubletNA3,
  hubletAP1,
  useCase,
  backgroundStars,
  gauge,
  yahooLogo,
  gmailLogo,
  outlookLogo,
  linkedinLogo
});