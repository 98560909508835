import { shouldNotSkipSetupUi } from 'growth-onboarding-signup-redirect/data/salesIntentLinkData';
import { Flow } from 'signup-constants/Flow';
import { StepStatus } from 'signup-constants/StepStatus';
import { SoftwareSignups } from '../../flow-management/SoftwareSignups';
import { isPostVerification } from '../../flow-settings/settingsSelectors';
import { getFlowKey } from '../../routing/getFlowKey';
import { getRouteParams } from '../../routing/getRouteParams';
import { isFlow } from '../../utils/isFlow';
import { getActiveError } from '../error/selectors';
export const getFlowState = state => state.flow;
export const getNextSteps = state => {
  const flowState = getFlowState(state);
  return flowState && flowState.nextSteps;
};
export const getPreVerificationSteps = state => {
  return getFlowState(state).preVerificationSteps;
};
export const getPostVerificationSteps = state => {
  return getFlowState(state).postVerificationSteps;
};
export const getPostAccountCreationSteps = state => {
  return getFlowState(state).postAccountCreationSteps;
};
export const getPostExistingAccountSelectionSteps = state => {
  return getFlowState(state).postExistingAccountSelectionSteps || [];
};
export const getFlowStepsFromState = state => {
  return {
    preVerification: getPreVerificationSteps(state),
    postVerification: getPostVerificationSteps(state),
    postAccountCreation: getPostAccountCreationSteps(state),
    postExistingAccountSelection: getPostExistingAccountSelectionSteps(state)
  };
};
export const getAllStepsArray = state => {
  return [...getPreVerificationSteps(state), ...getPostVerificationSteps(state), ...getPostAccountCreationSteps(state), ...getPostExistingAccountSelectionSteps(state)];
};
export const getNextStep = state => {
  const nextSteps = getNextSteps(state);
  return nextSteps && nextSteps.length > 0 ? nextSteps[0] : null;
};
export const getCurrentStep = state => {
  const flowState = getFlowState(state);
  return flowState && flowState.currentStep;
};
export const getCurrentStepStatus = state => {
  const flowState = getFlowState(state);
  return flowState && flowState.stepStatus;
};
export const isStepInNextOrCurrentStep = (state, steps = []) => {
  const preVerification = getPreVerificationSteps(state);
  const postVerification = getPostVerificationSteps(state);
  let nextSteps = getNextSteps(state) || [];
  if (!nextSteps.length) {
    nextSteps = isPostVerification() ? postVerification : preVerification;
  }
  const isStepInNextAndCurrentSteps = steps.some(step => nextSteps.includes(step));
  const response = !steps.length || isStepInNextAndCurrentSteps;
  return response;
};
export const getPrevFlowStates = state => {
  return getFlowState(state).prevFlowStates;
};
export const getPreviousStep = state => {
  const prevFlowStates = getPrevFlowStates(state);
  if (!prevFlowStates.length) {
    return null;
  }
  const lastIndex = prevFlowStates.length - 1;
  const {
    completedStep
  } = prevFlowStates[lastIndex];
  return completedStep;
};
export const getCompletedSteps = state => {
  const prevFlowStates = getPrevFlowStates(state);
  return prevFlowStates ? prevFlowStates.map(prevFlowState => prevFlowState.completedStep) : [];
};
export const isOnFirstStep = state => {
  const completedStep = getCompletedSteps(state);
  return !isPostVerification() && completedStep.length === 0;
};
export const isEndOfFlow = state => {
  const nextSteps = getNextSteps(state);
  const activeError = getActiveError(state);
  const currentStep = getCurrentStep(state);
  const noNextSteps = !nextSteps || nextSteps.length === 0;
  return !activeError && !currentStep && noNextSteps;
};
export const isEndOfUserOnlyFlow = state => {
  const nextSteps = getNextSteps(state);
  const activeError = getActiveError(state);
  const currentStep = getCurrentStep(state);
  const currentStepStatus = getCurrentStepStatus(state);
  const noNextSteps = !nextSteps || nextSteps.length === 0;
  const currentStepCheck = !currentStep || currentStepStatus === StepStatus.Completed;
  return !activeError && currentStepCheck && noNextSteps;
};
export const isSoftwareSignup = () => {
  return SoftwareSignups.includes(getFlowKey());
};
export const isPartnerSignup = () => {
  return isFlow([Flow.SolutionsProvider, Flow.DirectoryListing]);
};
export const isDeveloperFlow = () => {
  return isFlow([Flow.Developers, Flow.CmsDevelopers]);
};
export const isHSFXFLow = () => {
  return isFlow([Flow.HubspotForStartups, Flow.HubSpotPartners]);
};
export const hasPostExistingAccountSelectionQuestions = state => {
  const postExistingAccountSelection = getPostExistingAccountSelectionSteps(state);
  return postExistingAccountSelection.length > 1;
};
export const hasFlowLoaded = state => {
  return state.flow.hasFlowLoaded;
};
export const getShowProductSetupUIBranch = intentLink => {
  const {
    intentKey,
    queryParams
  } = getRouteParams();
  const isSalesFlow = isFlow(Flow.Sales);
  if (isSalesFlow && intentKey && intentLink) {
    return shouldNotSkipSetupUi({
      signupIntent: intentKey,
      queryParams,
      redirectUrl: intentLink
    });
  }
  return false;
};