import { getOnboardingRedirectUrl, setUpOnboardingConfig } from 'growth-onboarding-signup-redirect/redirect';
import { getRouteParams } from '../routing/getRouteParams';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { getFallbackOnboardingUrl } from './getFallbackOnboardingUrl';
import { redirectToIntent } from './redirectToIntent';
import { getShouldNotEnrollIntent } from '../intents/userGuideEnrollment';
import getOnboardingRedirectOptions from './getOnboardRedirectOptions';
import { memoize } from '../utils/memoize';
const LOG = Logger.getLogger('getOnboardingUrl');
export const getOnboardingUrl = memoize(({
  portalId,
  auth,
  survey,
  intentLink,
  hublet,
  signupExperimentTreatments
}, signupType, isNewUser, signupStepNumber) => {
  const {
    intentKey
  } = getRouteParams();
  const onboardingRedirectOptions = getOnboardingRedirectOptions(portalId, signupType, hublet, auth, signupStepNumber, survey, signupExperimentTreatments);
  if (intentKey && intentLink) {
    // Added by GO-Free team
    // User guide enrollment is the default behaviour. We opt-out the ones that are not mapped to a hub interest (hubInterestMapping)
    // Long term we would like all intents to be enrolled in the user guide.
    const shouldNotEnrollIntent = getShouldNotEnrollIntent(intentKey);
    return setUpOnboardingConfig(Object.assign({}, onboardingRedirectOptions, {
      // User guide enrollment
      doNotEnrollIntent: shouldNotEnrollIntent
    })).then(() => redirectToIntent({
      intentLink,
      survey,
      portalId,
      hublet,
      signupType,
      signupExperimentTreatments
    })).catch(error => LOG.error('setUpOnboardingConfig error:', {
      extra: getSentryExtra(error)
    }));
  }

  // If for some reason the automatic authentication fails
  // we'll redirect the user to getting-started to avoid
  // request errors on APIs that require the user to be authenticated
  if (auth.initialized && !auth.userId) {
    return getFallbackOnboardingUrl(portalId, hublet, isNewUser);
  }
  return Promise.resolve(getOnboardingRedirectUrl(onboardingRedirectOptions)).then(onboardingUrl => {
    return onboardingUrl;
  }).catch(error => {
    LOG.error('Failed to correctly acquire an onboarding redirect url from growth-onboarding-signup-redirect.', {
      extra: getSentryExtra(error)
    });
    return Promise.reject(error);
  });
}, (options, signupType) => {
  return `${JSON.stringify(options)}-${signupType}`;
});