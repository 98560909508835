/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

import { Data } from 'signup-constants/signupData/Data';
import { LoadingKey } from '../app-active-loading/LoadingKey';
import { isEmailVerificationSignupAndNotPostVerification, isntExistingUserExistingAccount, isntOAuthSignup } from '../app/verificationTypeSelectors';
import SignupClient from '../http-clients/SignupClient';
import { handleCompleteVerificationError } from '../http-complete-verification/handleCompleteVerificationError';
import { handleCompleteVerificationResponse } from '../http-complete-verification/handleCompleteVerificationResponse';
import { handleCompleteVerificationSuccess } from '../http-complete-verification/handleCompleteVerificationSuccess';
import { handleInitiateVerificationError, handleInitiateVerificationSuccess } from '../http-initiate-verification/handleInitiateVerificationResponse';
import { handleSignupError } from '../http-signup/handleSignupError';
import { handleSignupResponse } from '../http-signup/handleSignupResponse';
import { handleSignupSuccess } from '../http-signup/handleSignupSuccess';
import { generateRecaptchaToken } from '../recaptcha/utils/generateRecaptchaToken';
import { handleCodeVerificationError } from '../step-code-verification/handleCodeVerificationError';
import { handleCodeVerificationSuccess } from '../step-code-verification/handleCodeVerificationSuccess';
import { FlowActionTypes } from '../store/flow/action-types';
import { HttpResponseActionTypes } from '../store/http-responses/action-types';
import { getPreVerificationDataKeys } from '../store/signup-data/selectors';
import { CodeVerificationActionTypes } from '../store/step-code-verification/action-types';
import { Step } from 'signup-constants/Step';
import { addRequiredVerificationData } from './addRequiredVerificationData';
import { EndpointKey } from './EndpointKey';
import { shouldRequirePassword } from './shouldRequirePassword';
import { handleCodeVerificationRefreshSuccess } from '../step-code-verification/handleCodeVerificationRefreshSuccess';
import { getIsVerificationExpired } from '../store/app/selectors';
export const EndpointsSignupClient = {
  [EndpointKey.InitiateVerification]: {
    getRequestData: state => getPreVerificationDataKeys(state),
    stateCheck: isEmailVerificationSignupAndNotPostVerification,
    handleError: handleInitiateVerificationError,
    handleSuccess: (_, dispatch) => handleInitiateVerificationSuccess(dispatch),
    loadingKey: LoadingKey.InitiateVerificationRequest,
    method: SignupClient.initiateVerification,
    nextStep: [Step.Verification, Step.CodeVerification]
  },
  [EndpointKey.InitiateVerificationExpiredRefresh]: {
    getRequestData: state => getPreVerificationDataKeys(state),
    reduxActionType: CodeVerificationActionTypes.ExpiredRefreshInitiate,
    handleError: handleInitiateVerificationError,
    handleSuccess: (_, dispatch) => handleInitiateVerificationSuccess(dispatch),
    loadingKey: LoadingKey.InitiateVerificationRequestExpiredRefresh,
    method: SignupClient.initiateVerification,
    onBefore: dispatch => {
      return dispatch(generateRecaptchaToken('verification_expired'));
    }
  },
  [EndpointKey.CompleteVerification]: {
    getRequestData: () => [Data.Token],
    handleResponse: handleCompleteVerificationResponse,
    handleError: handleCompleteVerificationError,
    handleSuccess: handleCompleteVerificationSuccess,
    loadingKey: LoadingKey.CompleteVerificationRequest,
    method: SignupClient.completeVerification,
    stateCheck: isntOAuthSignup,
    once: true
  },
  [EndpointKey.CompleteVerificationByCode]: {
    getRequestData: () => [Data.CodeVerification, Data.Email],
    handleError: handleCodeVerificationError,
    handleSuccess: handleCodeVerificationSuccess,
    method: SignupClient.completeVerification,
    reduxActionType: CodeVerificationActionTypes.Start,
    stateCheck: state => {
      return isntOAuthSignup(state) && !getIsVerificationExpired(state);
    }
  },
  [EndpointKey.CompleteVerificationByCodeExpiredRefresh]: {
    getRequestData: () => [Data.CodeVerification, Data.Email],
    handleError: handleCodeVerificationError,
    handleSuccess: handleCodeVerificationRefreshSuccess,
    method: SignupClient.completeVerification,
    stateCheck: getIsVerificationExpired,
    reduxActionType: CodeVerificationActionTypes.Start
  },
  [EndpointKey.Signup]: {
    stateCheck: isntExistingUserExistingAccount,
    getRequestData: state => {
      return [...getPreVerificationDataKeys(state), ...addRequiredVerificationData(state), ...(shouldRequirePassword(state) ? [Data.Password] : [])];
    },
    handleError: handleSignupError,
    handleSuccess: handleSignupSuccess,
    handleResponse: handleSignupResponse,
    loadingKey: LoadingKey.SignupRequest,
    reduxActionType: FlowActionTypes.FinishSignup,
    method: SignupClient.signup,
    once: true,
    onBefore: (dispatch, action, getState) => {
      if (action.type !== HttpResponseActionTypes.RetryEndpoint || getIsVerificationExpired(getState())) {
        return dispatch(generateRecaptchaToken('before_finish_signup'));
      }
      return Promise.resolve();
    }
  }
};