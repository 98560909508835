import allSettled from 'hs-promise-utils/allSettled';
import { setupApp } from './app/setupApp';
import { setupAppError } from './app/setupAppError';
import { setExtraContext } from './app/setExtraContext';
import { setupAuth } from './auth/setupAuth';
import { setupCookieBanner } from './cookie-banner/setupCookieBanner';
import { getSentryExtra } from './error/getSentryExtra';
import { setupExperiments } from './experiments/setupExperiments';
import { setupFirealarm } from './firealarm/setupFirealarm';
import { setupGdpr } from './gdpr/setupGdpr';
import { setupExperimentalI18n } from './i18n/setupExperimenti18n';
import { setupI18n } from './i18n/setupI18n';
import { setupInterframe } from './interframe/setupInterframe';
import { setupApplicationLogger } from './logger/setupApplicationLogger';
import { setupRedux } from './redux/setupRedux';
import { getHistory } from './routing/history';
import { setupTalon } from './talon/setupTalon';
import { setupMonocle } from './monocle/setupMonocle';
export const App = {
  start() {
    setupFirealarm();
    const LOG = setupApplicationLogger();
    setExtraContext();
    try {
      allSettled([setupI18n(), setupExperimentalI18n(), setupInterframe()]).then(() => {
        const store = setupRedux();
        setupAuth(store);
        setupExperiments(store);
        setupGdpr(store);
        setupApp(store, getHistory());
      }).then(setupCookieBanner).then(setupTalon).then(setupMonocle)
      // If any rejections are returned from the methods above this will be called
      .catch(error => {
        setupAppError();
        LOG.error('An error occured while bootstraping Signup', {
          extra: getSentryExtra(error)
        });
      });
    } catch (error) {
      // If any uncaught errors are thrown from anything inside the try block this will be called.
      setupAppError();
      LOG.critical('Critical application crash.', {
        extra: getSentryExtra(error)
      });
    }
  }
};