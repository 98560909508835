import styled, { css, keyframes } from 'styled-components';
import UISelectableButton from 'UIComponents/button/UISelectableButton';
const config = {
  slidePx: 3,
  spacing: 0.05,
  animationDelay: 0.3,
  animationTime: 0.2
};
const translateStart = `transform: translateX(${config.slidePx}px);`;
const translateEnd = `transform: translateX(0);`;
const appear = keyframes(["0%{", " opacity:", ";}100%{", ";opacity:1;visibility:visible;}"], translateStart, 0.4, translateEnd);
const animationCss = ({
  index
}) => css(["animation:", " ", "s cubic-bezier(0.42,0,0.58,1);visibility:hidden;animation-fill-mode:forwards;transition:all;", ""], appear, config.animationTime, index && `animation-delay: ${config.animationDelay + config.spacing * index + 0.01 * Math.pow(index, 1.9)}s;`);
export const AnimatedButton = styled(UISelectableButton).withConfig({
  displayName: "ButtonAnimationsstyles__AnimatedButton",
  componentId: "sc-1b9rejk-0"
})(["", ""], props => {
  if (props.disable) return ``;
  return animationCss;
});