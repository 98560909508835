import { Flow } from 'signup-constants/Flow';
import { getApplicationDomain } from '../constants/apiEnvironments';
import { isFlow } from '../utils/isFlow';
export const getLoginUrl = ({
  redirectUrl,
  email,
  hublet
} = {}) => {
  let subpath = '/beta';
  let search = redirectUrl && Boolean(redirectUrl) ? `?loginRedirectUrl=${encodeURIComponent(redirectUrl)}` : '';
  if (email && Boolean(redirectUrl)) {
    search = `${search}${search ? '&' : '?'}email=${encodeURIComponent(email)}`;
  }
  if (isFlow(Flow.Connect)) {
    subpath = '/connect';
  }
  return `${getApplicationDomain(hublet)}/login${subpath}${search}`;
};