import { Flow } from 'signup-constants/Flow';
import { getIntentKey } from '../routing/getIntentKey';
import { isFlow } from '../utils/isFlow';
import { ExperimentKeys, TEST_KEY } from './ExperimentKeys';
import { enrollOnFlow } from './experimentDefaults';
import { getIsMobile } from '../utils/deviceUtils';
import { getCountryCode, getIsNewUser } from '../store/app/selectors';
import { getQueryParam } from '../routing/getQueryParam';
import { getIsMonthlySingleProduct } from '../store/purchase/selectors';
import { CountryCode } from 'signup-ui-lego-core/constants/CountryCode';
export const EXPERIMENT_ID = 'experimentId';
const SEEN_EXPERIMENTS = new Set();
const Experiments = new Map([[ExperimentKeys.ACQ0151, {
  shouldEnroll: state => {
    const countryUs = getCountryCode(state) === CountryCode.US;
    const isCountryUsOrOverride = getQueryParam('us-override') === 'true' ? true : countryUs;
    return getIsMonthlySingleProduct(state) && isCountryUsOrOverride;
  }
}], [ExperimentKeys.ACQ0156, {
  shouldEnroll: () => true
}], [ExperimentKeys.COAC0002, {
  shouldEnroll: () => {
    const isEnrolledFlow = isFlow([Flow.Crm, Flow.Marketing, Flow.Sales, Flow.Service]);
    const isMobile = getIsMobile();
    const intentKey = getIntentKey();
    return isEnrolledFlow && !isMobile && !intentKey;
  }
}], [ExperimentKeys.ACQ0154, {
  shouldEnroll: state => {
    const isEnrolledFlow = isFlow([Flow.Crm, Flow.Marketing, Flow.Sales, Flow.Service]);
    const isNewUser = getIsNewUser(state);
    return isEnrolledFlow && isNewUser;
  }
}], [ExperimentKeys.ACQ0157, {
  shouldEnroll: () => false
}], [ExperimentKeys.GOSU0006, {
  shouldEnroll: state => {
    const isEnrolledFlow = isFlow([Flow.Crm, Flow.Marketing, Flow.Sales, Flow.Service]);
    const isMobile = getIsMobile();
    const intentKey = getIntentKey();
    const isNewUser = getIsNewUser(state);
    return isEnrolledFlow && isNewUser && !isMobile && !intentKey;
  }
}]]);
export const getExperiment = id => {
  // we want to prefer this solution over mocking getExperiment so that the
  // seen experiments tracking below continues to work and doesn't get
  // accidentally mocked
  if (id === TEST_KEY) {
    return {
      shouldEnroll: () => enrollOnFlow([Flow.Crm])
    };
  }
  SEEN_EXPERIMENTS.add(id);
  return Experiments.get(id);
};
export function getSeenExperimentsForTesting() {
  return Array.from(SEEN_EXPERIMENTS);
}