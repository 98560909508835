// @ts-expect-error Untyped module
import Cookies from 'js-cookie';
import { getGatesWithUrlOverrides } from '../auth/authUtils';
import { getActiveExperimentsParametersForInitiateVerification } from '../experiments/ExperimentUtils';
import { getAmplitudeEventBody } from '../hubspot-forms/getAmplitudeEventBody';
import { getPreferredReferrer } from '../i18n/ReferrerUtils';
import { getQueryParamsNoEmbedderOptions } from '../routing/getQueryParamsNoEmbedderOptions';
import { getRouteParams } from '../routing/getRouteParams';
import { Data } from 'signup-constants/signupData/Data';
import { getQueryParamsNoSignupData } from '../signup-data/getQueryParamsNoSignupData';
import { getValidatedCompanyDomain } from '../signup-data/getValidatedCompanyDomain';
import { getExperimentUtk } from '../store/experiments/selectors';
import { getFlatSignupDataValueMap } from '../store/signup-data/selectors';
import { getTalonValue } from '../talon/getTalonValue';
import { getSignupMode } from '../tracking/getSignupMode';
import { getImpactAdditionalParams } from '../utils/affiliateUtils';
import { screamingSnakeToCamel } from '../utils/data-structure-utils/screamingSnakeToCamel';
import { endpointForwardedUtms } from '../utm/utmHelpers';
import { getMicroappKey } from '../routing/getMicroappKey';
import { getProblemAwarenessForApi, getProductExperienceForApi } from '../signup-data/considerationProfileUtils';
import { getIsVerificationExpired } from '../store/app/selectors';
import { getRecaptchaSiteKey, getRecaptchaToken } from '../store/google-recaptcha/selectors';
import Logger from '../lib/Logger';
const LOG = Logger.getLogger('getInitiateVerificationBody');
export const getOdysseySignupDataBody = state => {
  const userInformation = [Data.Email, Data.FirstName, Data.LastName];
  const signupData = getFlatSignupDataValueMap(state);
  return {
    odysseySignupData: Object.assign({
      experimentId: getExperimentUtk(state)
    }, Object.keys(signupData).filter(dataKey => ![...userInformation, Data.Token].includes(dataKey)).reduce((result, dataKey) => {
      const snakeDataKey = screamingSnakeToCamel(dataKey);
      result[snakeDataKey] = dataKey === Data.CompanyDomain ? getValidatedCompanyDomain(signupData[dataKey]) : signupData[dataKey];
      return result;
    }, {}), {
      problemAwareness: getProblemAwarenessForApi(state),
      productExperience: getProductExperienceForApi(state)
    })
  };
};
export const getInitiateVerificationBody = state => {
  const talon = getTalonValue();
  const signupData = getFlatSignupDataValueMap(state);
  const verificationExpired = getIsVerificationExpired(state);
  const {
    queryParams,
    flowKey
  } = getRouteParams();
  const howUtms = endpointForwardedUtms(queryParams);
  const queryParamsWithoutSignupData = getQueryParamsNoSignupData(queryParams, signupData);
  const queryParamsNoEmbedderOptions = getQueryParamsNoEmbedderOptions(queryParamsWithoutSignupData);
  const params = Object.keys(queryParamsNoEmbedderOptions).filter(key => key !== 'gates').reduce((res, key) => Object.assign({}, res, {
    [key]: queryParamsNoEmbedderOptions[key]
  }), {});
  const activeExperimentsParameters = getActiveExperimentsParametersForInitiateVerification(state);
  const gatesOverrides = {
    gates: getGatesWithUrlOverrides([])
  };
  const chatbotIdentifier = Cookies.get('messagesUtk') ? {
    messagesUtk: Cookies.get('messagesUtk')
  } : {};
  const recaptchaToken = getRecaptchaToken(state);
  const recaptchaSiteKey = getRecaptchaSiteKey(state);
  if (!recaptchaToken || !recaptchaSiteKey) {
    LOG.info('Missing recaptcha information for Initiate Verification', {
      extra: {
        flowKey,
        recaptchaToken,
        recaptchaSiteKey
      }
    });
  }
  const additionalUrlParams = Object.assign({}, params, howUtms, getImpactAdditionalParams(), {
    preReferrer: getPreferredReferrer(),
    flowPath: flowKey,
    signupMode: getSignupMode()
  }, chatbotIdentifier, {
    uuid: queryParams['uuid']
  });
  const microapp = getMicroappKey();
  const initiateVerificationBody = Object.assign({
    additionalUrlParams
  }, getOdysseySignupDataBody(state), {
    email: signupData[Data.Email],
    talon,
    activeExperimentsParameters,
    gatesOverrides,
    settings: []
  }, verificationExpired && {
    hideAlternativeVerificationLink: true
  }, getAmplitudeEventBody(state), microapp && {
    microapp
  }, recaptchaToken && {
    recaptchaToken
  }, recaptchaSiteKey && {
    recaptchaSiteKey
  });
  return initiateVerificationBody;
};