import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import enviro from 'enviro';
import { ConvertGoalType } from '../convert/ConvertGoalType';
import { useConvertTrack } from '../convert/useConvertTrack';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { Data } from 'signup-constants/signupData/Data';
import { setHublet, setIsExistingUserSession, setIsNewUser } from '../store/app/action-creators';
import { redirectExistingUserAction, setPortalId } from '../store/auth/action-creators';
import { getAuthenticatedUser } from '../store/auth/selectors';
import { getExistingAccounts, isHubless } from '../store/existing-accounts/selectors';
import { bulkEmptySignupDataAndSetFresh } from '../store/signup-data/action-creators';
import { useTracker } from '../tracking/useTracker';
import { Step } from 'signup-constants/Step';
import { useView } from '../views/useView';
import { setHasSignupInitiatedBeenTracked } from '../tracking/useTrackSignupInitiated';
import TrackerContainer from '../tracking/TrackerContainer';
import { resetExposureTracking } from '../experiments/useExperiment';
import { checkAcq151CanBeReenrolled } from '../store/purchase/action-creators';
import { getUnenrolledExperiments } from '../store/experiments/selectors';
import { ExperimentKeys } from '../experiments/ExperimentKeys';
import { continueWithThisExistingUser } from './continueWithThisUser';

/**
 * Hook for the Existing User Screen.
 *
 * When user selects continue with this user:
 * - Updates the store with the user's details,
 * - Sets correct Error if one occurs when fetching accounts.
 * - Removes unnecessary steps (password, landing page, verification).
 *
 * When user selects Create New Account:
 * - Empties any data in the store and sets validation to Fresh.
 */

export const useExistingUser = () => {
  const dispatch = useDispatch();
  const user = useSelector(getAuthenticatedUser);
  const {
    submit
  } = useView({
    stepKey: Step.ExistingUser
  });
  const accounts = useSelector(getExistingAccounts);
  const uenrolledExperiments = useSelector(getUnenrolledExperiments);
  const userIsHubless = useSelector(isHubless);
  const Tracker = useTracker();
  setHasSignupInitiatedBeenTracked(false);
  useConvertTrack(ConvertGoalType.ExistingUser);
  useEffect(() => {
    dispatch(setIsNewUser(false));
    dispatch(setIsExistingUserSession(true));
  }, [dispatch]);

  // Set the users email in case it has been cleared by trying to create a new user and clicking back
  useEffect(() => {
    if (user && user.email) {
      TrackerContainer.setProperties({
        email: user.email
      });
    }
  }, [user]);
  const continueWithThisUser = () => {
    return dispatch(continueWithThisExistingUser());
  };
  const createNewUser = event => {
    event.preventDefault();
    if (uenrolledExperiments.includes(ExperimentKeys.ACQ0151)) {
      dispatch(checkAcq151CanBeReenrolled());
    }
    resetExposureTracking();
    dispatch(bulkEmptySignupDataAndSetFresh([Data.FirstName, Data.LastName, Data.Email, Data.CompanyName, Data.CompanyDomain], {
      shouldEmptyPrefilledValues: false
    }));
    Tracker.signupInteraction('selected-create-new-user');
    dispatch(setIsNewUser(true));
    TrackerContainer.recreateTracking();
    submit();
  };
  const redirectAcademy = () => {
    if (userIsHubless) {
      return continueWithThisUser();
    }
    let bestScore = -Infinity;
    let portalId = 0;
    let hublet = enviro.getHublet();
    if (accounts) {
      accounts.forEach(account => {
        const scoringFunction = getFlowConfig().scoringFunction;
        const score = scoringFunction(account);
        if (score > bestScore) {
          portalId = account.id;
          hublet = account.hublet;
          bestScore = score;
        }
      });
    }
    Tracker.signupInteraction('embedded-academy-redirect');
    dispatch(setPortalId(portalId));
    dispatch(setHublet(hublet));
    return dispatch(redirectExistingUserAction({
      portalId,
      hublet
    }));
  };
  return {
    user,
    isHubless: userIsHubless,
    continueWithThisUser,
    createNewUser,
    redirectAcademy
  };
};